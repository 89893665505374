<template>
  <v-container fluid>
    <v-card>
      <!-- Header -->
      <div class="appointment-header">
        <h1>Appointment</h1>
        <v-spacer></v-spacer>
        <v-btn color="#CAD3C8" @click="openAddAppointmentDialog">
          <v-icon left>mdi-plus</v-icon>
          <span class="hidden-sm-and-down">ADD APPOINTMENT</span>
        </v-btn>
      </div>

      <!-- Appointment Dialog -->
      <v-dialog v-model="dialog" max-width="500px" content-class="custom-dialog" @click:outside="resetForm">
        <v-card class="pa-5" elevation="4" max-height="93vh" style="overflow-y: auto;">
          <v-card-title class="text-h5 text-center">Book an Appointment</v-card-title>
          <v-card-text>
            <v-form ref="form" @submit.prevent="submitForm">
              <!-- <v-text-field
                v-model="user.userName"
                label="Dealer Name"
                required
                outlined
                dense
                readonly
              ></v-text-field> -->

              <input type="hidden" v-model="form.dealerId" />
              <!-- <v-text-field v-model="form.emailid" label="Email" required outlined dense :rules="[v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Enter a valid email']"></v-text-field> -->              
              <v-text-field v-model="form.regnum" label="Reg Number" required outlined dense :rules="[v => !!v || 'Registration number is required']"></v-text-field>
              <v-text-field v-model="form.mobile" label="Phone" required outlined dense :rules="[v => !!v || 'Phone number is required', v => /^[0-9]{10}$/.test(v) || 'Enter a valid 10-digit phone number']"></v-text-field>
              <v-text-field v-model="form.service_type" label="Service Type" required outlined dense :rules="[v => !!v || 'Service type is required']"></v-text-field>
              <v-text-field v-model="form.description" label="Description" required outlined dense :rules="[v => !!v || 'Description is required']"></v-text-field>
              <v-text-field v-model="form.status" label="Status" required readonly outlined dense :rules="[v => !!v || 'Status is required']"></v-text-field>
              
              <!-- <v-select
                v-model="form.service_type"
                :items="serviceOptions"
                label="Select Service Type"
                item-text="planName"
                item-value="planId"
                required
                outlined
                dense
                :rules="[v => !!v || 'Service type is required']"
                @change="onServiceTypeChange"
              ></v-select> -->
              
              <!-- <v-card v-if="selectedPlan" class="mb-4" outlined>
                <v-card-text>
                  <div><strong>Plan Name:</strong> {{ selectedPlan.planName }}</div>
                  <div><strong>Description:</strong> {{ selectedPlan.description }}</div>
                  <div><strong>Amount:</strong> {{ selectedPlan.amount }}</div>
                  <div><strong>Period:</strong> {{ selectedPlan.period }}</div>
                </v-card-text>
              </v-card> -->
              
              <v-text-field v-model="form.scheduled_at" label="Scheduled Date" type="date" required outlined dense :rules="[v => !!v || 'Scheduled date is required']"></v-text-field>
            
              <v-row class="mt-4" justify="center">
                <v-col cols="6">
                  <v-btn type="submit" color="primary" block>Submit</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn color="error" block @click="cancelForm">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-form>
            
            <v-alert v-if="message" :type="messageType" class="mt-3" dense>
              {{ message }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <!-- Side Tabs -->
          <v-col cols="12" md="2">
            <v-card flat>
              <v-list shaped>
                <v-list-item-group v-model="activeTab" color="primary">
                  <v-list-item value="0">
                    <v-list-item-icon>
                      <v-icon>mdi-clock-time-one-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Requested</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item value="1">
                    <v-list-item-icon>
                      <v-icon>mdi-notebook-plus-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Scheduled</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item value="2">
                    <v-list-item-icon>
                      <v-icon>mdi-progress-wrench</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Ongoing</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item value="3">
                    <v-list-item-icon>
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Completed</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <!-- Content Area -->
          <v-col cols="12" md="10">
            <v-card flat>
              <!-- Search Field -->
              <v-card-title>
                <v-text-field
                  v-model="search"
                  label="Search"
                  prepend-icon="mdi-magnify"
                  single-line
                  hide-details
                  class="mb-4"
                ></v-text-field>
              </v-card-title>

              <!-- Tab Content -->
              <v-window v-model="activeTab">
                <!-- Requested Appointments Tab -->
                <v-window-item value="0">
                  <h3 class="ml-4 mt-2 mb-2">Requested Appointments</h3>
                  <v-data-table
                    :headers="AppointmentHeaders2"
                    :items="filteredRequestedAppointments"
                    :search="search"
                    class="elevation-1"
                    fixed-header
                    :options="{ itemsPerPage: 5 }"
                    mobile-breakpoint="600"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr :key="`${item.dealerId}-${item.service_type}-${index}`">
                        <td class="text-left">{{ item.dealerId }}</td>
                        <td class="text-left">{{ item.dealerName }}</td>
                        <td class="text-left">{{ item.description }}</td>
                        <td class="text-left">{{ item.service_type }}</td>
                        <td class="text-left">{{ item.mobile }}</td>
                        <td class="text-left">{{ item.emailid }}</td>
                        <td class="text-left">{{ item.status }}</td>
                        <td class="text-center">
                          <v-btn color="warning" @click="cancelAppointment(item)" small >Cancel</v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-window-item>

                <!-- New Appointments Tab -->
                <v-window-item value="1">
                  <h3 class="ml-4 mt-2 mb-2">Scheduled Appointments</h3>
                  <v-data-table
                    :headers="AppointmentHeaders"
                    :items="filteredNewAppointments"
                    :search="search"
                    class="elevation-1"
                    fixed-header
                    :options="{ itemsPerPage: 5 }"
                    mobile-breakpoint="600"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr :key="`${item.dealerId}-${item.service_type}-${index}`">
                        <td class="text-left">{{ item.dealerId }}</td>
                        <td class="text-left">{{ item.dealerName }}</td>
                        <td class="text-left">{{ item.description }}</td>
                        <td class="text-left">{{ item.service_type }}</td>
                        <td class="text-left">{{ item.mobile }}</td>
                        <td class="text-left">{{ item.emailid }}</td>
                        <td class="text-left">{{ item.status }}</td>
                        <!-- <td class="text-center">
                          <v-btn color="primary" @click="startAppointment(item)" small>Start Service</v-btn>
                        </td> -->
                      </tr>
                    </template>
                  </v-data-table>
                </v-window-item>

                <!-- Ongoing Appointments Tab -->
                <v-window-item value="2">
                  <h3 class="ml-4 mt-2 mb-2">Ongoing Appointments</h3>
                  <v-data-table
                    :headers="AppointmentHeaders"
                    :items="filteredOngoingAppointments"
                    :search="search"
                    class="elevation-1"
                    fixed-header
                    :options="{ itemsPerPage: 5 }"
                    mobile-breakpoint="600"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr :key="`${item.dealerId}-${item.service_type}-${index}`">
                        <td class="text-left">{{ item.dealerId }}</td>
                        <td class="text-left">{{ item.dealerName }}</td>
                        <td class="text-left">{{ item.description }}</td>
                        <td class="text-left">{{ item.service_type }}</td>
                        <td class="text-left">{{ item.mobile }}</td>
                        <td class="text-left">{{ item.emailid }}</td>
                        <td class="text-left">{{ item.status }}</td>
                        <!-- <td class="text-center">
                          <v-btn color="secondary" @click="ongoingAppointment(item)" small>Complete</v-btn>
                        </td> -->
                      </tr>
                    </template>
                  </v-data-table>
                </v-window-item>

                <!-- Completed Appointments Tab -->
                <v-window-item value="3">
                  <h3 class="ml-4 mt-2 mb-2">Completed Appointments</h3>
                  <v-data-table
                    :headers="AppointmentHeaders3"
                    :items="filteredCompletedAppointments"
                    :search="search"
                    class="elevation-1"
                    fixed-header
                    :options="{ itemsPerPage: 5 }"
                    mobile-breakpoint="600"
                  >
                    <template v-slot:item="{ item, index }">
                      <tr :key="`${item.dealerId}-${item.service_type}-${index}`">
                        <td class="text-left">{{ item.dealerId }}</td>
                        <td class="text-left">{{ item.dealerName }}</td>
                        <td class="text-left">{{ item.description }}</td>
                        <td class="text-left">{{ item.service_type }}</td>
                        <td class="text-left">{{ item.mobile }}</td>
                        <td class="text-left">{{ item.emailid }}</td>
                        <td class="text-left">{{ item.status }}</td>
                        <td class="text-center">
                          <v-btn color="secondary" disabled small>View</v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("fleetuser")),
      // user:null,
      search: "",
      dialog: false,
      activeTab: '0', // Default to the first tab
      // search:'',
      // installerId: '141',  // Dynamically set installerId
      dealerId: '',  // Dynamically set dealerId
      // dealerId:[],
      form: {
        dealerId: '',
        emailid: '',
        mobile: '',
        regnum: '',
        description: '',
        service_type: '',
        status: 'requested',
        scheduled_at: '',
        started_at: '',
        ended_at: '',
        installer: '',
        lat: '',
        lon: '',
        created_at: '',
        updated_at: ''
      },
      message: '',
      messageType: '',
      serviceOptions: [], // Stores the list of plans from the API
      selectedPlan: null, // Stores the details of the selected plan
    //   user: {
    //   userName: 'Dealer Name'
    // },
      // serviceOptions: [
        // { text: 'Select a service', value: '' },
        // { text: 'Consultation', value: 'consultation' },
        // { text: 'Repair Service', value: 'repair' },
        // { text: 'Maintenance', value: 'maintenance' },
        // { text: 'New-installation', value: 'New-installation' }
      // ],
      AppointmentHeaders2: [
        { text: "User ID", value: "dealerId", align: "start" },
        { text: "User Name", value: "UserName", align: "start" },
        { text: "Description", value: "Description", align: "start" },
        { text: "Service Type", value: "service_type", align: "start" },
        { text: "Mobile", value: "mobile", align: "start" },
        { text: "Email", value: "emailid", align: "start" },
        { text: "Status", value: "status", align: "start" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      AppointmentHeaders3: [
        { text: "User ID", value: "dealerId", align: "start" },
        { text: "User Name", value: "UserName", align: "start" },
        { text: "Description", value: "Description", align: "start" },
        { text: "Service Type", value: "service_type", align: "start" },
        { text: "Mobile", value: "mobile", align: "start" },
        { text: "Email", value: "emailid", align: "start" },
        { text: "Status", value: "status", align: "start" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      AppointmentHeaders: [
        { text: "User ID", value: "dealerId", align: "start" },
        { text: "User Name", value: "UserName", align: "start" },
        { text: "Description", value: "Description", align: "start" },
        { text: "Service Type", value: "service_type", align: "start" },
        { text: "Mobile", value: "mobile", align: "start" },
        { text: "Email", value: "emailid", align: "start" },
        { text: "Status", value: "status", align: "start" },
      ],
      filteredNewAppointments: [],
      filteredOngoingAppointments: [],
      filteredCompletedAppointments: [],
      filteredRequestedAppointments: [],
    //   requestedAppointments: [],
    // newAppointments: [],
    // ongoingAppointments: [],
    // completedAppointments: []
    };
  },
  // watch: {
  // appointments(newValue) {
  //   console.log("Appointments Updated:", newValue);
  // }
  watch: {
  activeTab(newVal) {
    console.log('Active Tab Changed:', newVal);
  }
},
  created() {
    // this.fetchAppointments();
    // console.log(this.user,"gggggg4444444")
    //   console.log(this.user.dealerId,"gggggg")
  },

mounted() {
    // console.log("Component mounted");
    this.initializeData(); // Set dealerId from user data
    if (this.dealerId) {
      // this.form.dealerId = this.user.userName;
      this.form.dealerId = this.dealerId; // Set dealerId in the form object
      // console.log("Dealer ID is set. Fetching appointments...");
      this.fetchAppointments(); // Fetch appointments only if dealerId is set
    } else {
      console.error("Dealer ID is missing. Cannot fetch appointments.");
    }
  },
// mounted() {
//     this.fetchAppointments();
//   },
  methods: {
     // Initialize dealerId from user data
     initializeData() {
      // Retrieve the user object from localStorage
      this.user = JSON.parse(localStorage.getItem("fleetuser"));
      // console.log("User object from localStorage:", this.user);

      // Check if the user object and dealerId exist
      if (this.user && this.user.dealerId) {
        this.dealerId = this.user.dealerId;
        this.form.dealerId = this.user.dealerId; // Set dealerId in the form object
        // console.log("Dealer ID set from user data:", this.dealerId);
      } else {
        // console.error("User data or dealerId not found in localStorage");
        this.message = "User data not found. Please log in again.";
        this.messageType = "error";
      }
    },

    async fetchAppointments() {
  try {
    // Ensure dealerId is set
    if (!this.dealerId) {
      // console.error("Missing dealerId");
      this.message = "Dealer ID is missing. Please check your account details.";
      this.messageType = "error";
      return;
    }

    // Construct the API URL
    const url = `https://robopower.xyz/us/appointments/getappointments?dealerId=${this.dealerId}`;
    // console.log("Fetching data from:", url);

    // Make the API request
    const response = await axios.get(url);
    // console.log("API Response:", response);

    // Check if the response is null or undefined
    if (response.data === null || response.data === undefined) {
      console.error("API returned null or undefined response");
      this.message = "No appointments found for the provided Dealer ID.";
      this.messageType = "info";
      return;
    }

    // Check if the response data is an array
    if (Array.isArray(response.data)) {
      // console.log("API response is an array. Processing data...");

      // Categorize appointments by status
      const otherRequests = response.data.filter(apt => apt.installer && apt.status !== 'requested');
      this.filteredRequestedAppointments = response.data.filter(apt => !apt.installer || apt.status === 'requested');
      this.filteredNewAppointments = otherRequests.filter(apt => apt.status === 'scheduled');
      this.filteredOngoingAppointments = otherRequests.filter(apt => apt.status === 'inprogress');
      this.filteredCompletedAppointments = otherRequests.filter(apt => apt.status === 'completed');

      this.messageType = "success";
    } else {
      // console.error("Unexpected API response structure:", response.data);
      this.message = "Unexpected data format. Please contact support.";
      this.messageType = "error";
    }
  } catch (error) {
    // console.error("Error fetching appointments:", error);
    this.message = "An error occurred while fetching appointments. Please try again.";
    this.messageType = "error";
  }
},
  // },
  // },

  // },


//   async fetchAppointments() {
//   try {
//     // Ensure installerId and dealerId are set before making the request
//     if (!this.installerId || !this.dealerId) {
//       console.error("Missing installerId or dealerId");
//       return;
//     }

//     const url = `https://robopower.xyz/us/appointments/getappointments?installerId=${this.installerId}&dealerId=${this.dealerId}`;
//     console.log("Fetching data from:", url);

//     const response = await axios.get(url);
//     console.log("API Response:", response.data);

//     if (Array.isArray(response.data)) {
//       // Filter out requested appointments
//       const otherRequests = response.data.filter(apt => apt.installer && apt.status !== 'requested');

//       // Categorize appointments by status
//       this.filteredRequestedAppointments = response.data.filter(apt => !apt.installer || apt.status === 'requested');
//       this.filteredNewAppointments = otherRequests.filter(apt => apt.status === 'scheduled');
//       this.filteredOngoingAppointments = otherRequests.filter(apt => apt.status === 'inprogress');
//       this.filteredCompletedAppointments = otherRequests.filter(apt => apt.status === 'completed');

//       // Log categorized data for debugging
//       console.log("Requested Appointments:", this.filteredRequestedAppointments);
//       console.log("New Appointments:", this.filteredNewAppointments);
//       console.log("Ongoing Appointments:", this.filteredOngoingAppointments);
//       console.log("Completed Appointments:", this.filteredCompletedAppointments);
//     } else if (Array.isArray(response.data?.data)) {
//       this.appointments = response.data.data;
//       // console.log("Appointments from nested data:", this.appointments);
//     } else {
//       console.error("Unexpected API response structure:", response.data);
//     }
//   } catch (error) {
//     console.error("Error fetching appointments:", error);
//   }
// },
    openAddAppointmentDialog() {
      this.dialog = true;
    },
    
    async submitForm() {
      if (!this.isValidForm()) {
        this.message = 'Please provide valid details';
        this.messageType = 'error';
        return;
      }
      
      try {
        this.form.created_at = moment().format('MM/DD/YYYY hh:mm:ss');
        const formData = new FormData();
    formData.append('dealerId', this.user.dealerId);
    formData.append('emailid', this.user.emailId);
    formData.append('mobile', this.form.mobile);
    formData.append('regnum', this.form.regnum);
    formData.append('description',this.form.description)
    formData.append('service_type', this.form.service_type);
    formData.append('status', this.form.status);
    formData.append('scheduled_at', moment(this.form.scheduled_at).format("YYYY-MM-DD","MM/DD/YYYY hh:mm:ss"));
    formData.append('created_at', this.form.created_at);
    formData.append('updated_at',this.form.updated_at);

        const response = await axios.post('https://robopower.xyz/us/appointments/addappointment', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        
        if (response.status === 200) {
          this.message = 'Appointment booked successfully!';
          this.messageType = 'success';

  // Fetch updated data from the server after adding a new appointment
  await this.fetchAppointments();

          // Hide the message and close dialog after 3 seconds
          setTimeout(() => {
            this.message = '';
            this.dialog = false;
            this.resetForm();
          }, 1000);
        }
      } catch (error) {
        this.message = 'Error: ' + (error.response?.data?.message || error.message);
        this.messageType = 'error';

        // Hide the error message after 5 seconds
        setTimeout(() => {
          this.message = '';
        }, 2000);
      }
    },
    isValidForm() {
      return this.form.mobile && 
             this.form.regnum && 
             this.form.status && 
             this.form.scheduled_at && 
             this.form.description &&
             this.form.service_type ;
    },
    resetForm() {
      this.$refs.form.resetValidation(); // Reset validation state
      this.form = {
        // dealerId: '',
        dealerId:this.dealerId,
        emailid: '',
        mobile: '',
        regnum: '',
        description: '',
        service_type: '',
        status: 'requested',
        scheduled_at: '',
        started_at: '',
        ended_at: '',
        installer: '',
        lat: '',
        lon: '',
        created_at: '',
        updated_at: ''
      };
      this.selectedPlan = null; // Reset selected plan
      this.message = '';
    },
    cancelForm() {
      this.resetForm();
      this.dialog = false;
    },
    startAppointment(item) {
      const index = this.filteredNewAppointments.indexOf(item);
      if (index > -1) {
        this.filteredNewAppointments.splice(index, 1);
        item.status = "inprogress";
        this.filteredOngoingAppointments.push(item);
      }
    },
     async cancelAppointment(item) {
   
      const Swal = require("sweetalert2");
      await Swal.fire({
        title: "Are You Sure? You Want To Cancel the Appointment",
        text: "You Can't Revert Your Action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Cancel it!",
        cancelButtonText: "No, Keep it!",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then( async(result) => {
        if (result.value) {
        const formData = new FormData();
    formData.append('id', item.id);
    formData.append('updated_at',moment().format('MM/DD/YYYY hh:mm:ss'));

        const response = await axios.post('https://robopower.xyz/us/appointments/cancelappointment', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        
        if (response.status === 200) {
          this.message = 'Appointment cancelled successfully!';
          this.messageType = 'success';

  // Fetch updated data from the server after adding a new appointment
        await this.fetchAppointments();

          // Hide the message and close dialog after 3 seconds
          setTimeout(() => {
            this.message = '';
            this.dialog = false;
          }, 1000);
        }
      }
    })
  
    },
    ongoingAppointment(item) {
      const index = this.filteredOngoingAppointments.indexOf(item);
      if (index > -1) {
        this.filteredOngoingAppointments.splice(index, 1);
        item.status = "completed";
        this.filteredCompletedAppointments.push(item);
      }
    },
  //   acceptAppointment(item) {
  //   const index = this.filteredRequestedAppointments.indexOf(item);
  //   if (index > -1) {
  //     this.filteredRequestedAppointments.splice(index, 1);
  //     item.status = "scheduled";
  //     this.filteredNewAppointments.push(item);
  //   }
  // }

  async acceptAppointment(item) {
      try {
        // Get current user ID from the component's data
        const userId = this.installerId;
        
        // Construct the API URL
        const url = `https://robopower.xyz/us/appointments/acceptrequest/${item.id}`;
        // console.log("userrrrrrrr",url)
        
        // Format the current date/time
        const updatedAt = moment().format("MM/DD/YYYY hh:mm:ss");
        
        // Create form data
        const formData = new FormData();
        formData.append("installer", userId.toString());
        formData.append("updated_at", updatedAt);
        
        // Make the API call
        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          // Show success message
          this.$emit('show-snackbar', {
            message: response.data || 'Appointment accepted successfully',
            color: 'success'
          });

          // Update the local state
          const index = this.filteredRequestedAppointments.indexOf(item);
          if (index > -1) {
            this.filteredRequestedAppointments.splice(index, 1);
            item.status = "scheduled";
            item.installer = userId;
            this.filteredNewAppointments.push(item);
          }

          // Refresh the appointments data
          await this.fetchAppointments();
        }
      } catch (error) {
        // Handle errors
        console.error("Error accepting appointment:", error);
        
        const errorMessage = error.response?.data || 'Error accepting appointment';
        this.$emit('show-snackbar', {
          message: errorMessage,
          color: 'error'
        });
      }
    },

  }
};
</script>

<style scoped>
.v-container {
  overflow-x: hidden;
}

.appointment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1976d2;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.appointment-header h1 {
  color: white;
  font-weight: bold;
  margin-right: 10px;
  padding: 0px 30px;
}

.v-card {
  overflow-y: auto;
}

.custom-dialog {
  margin-top: 100px !important; 
}

.v-list-item {
  min-height: 48px;
  padding: 8px 16px; /* Add padding to list items */
}

.v-list-item-title {
  font-weight: 500;
}

.v-list-item.v-item--active {
  background-color: #e3f2fd;
}

.v-window {
  height: 100%;
  padding: 16px; /* Add padding to the content area */
}

h3 {
  color: #1976d2;
  font-weight: 500;
  margin-left: 16px; /* Add margin to the heading */
}

.v-data-table {
  margin: 16px; /* Add margin to the data tables */
}

.v-text-field {
  margin-bottom: 16px; /* Add margin to the search field */
}
</style>

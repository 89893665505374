<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height mainbody" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="5">
            <v-card class="elevation-12">
              <v-toolbar class="multi-color-toolbar">
                <v-row>
                  <v-toolbar-title><img src='../../public/old_logo.png' width="30%" /></v-toolbar-title>
                </v-row>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form class="text-xs-center" dark="dark" ref="form" lazy-validation>
                  <v-container id="dropdown-example-3">
                    <v-text-field dense outlined @keyup.enter="onTrack" id="selectedemail" class="custom-text-field"
                      required :color="textFieldColor" @click="changeColor" v-model="selectedEmail"
                      label="Enter Email ID" persistent-placeholder name="email" prepend-icon="mdi-account" type="text">
                    </v-text-field>

                    <v-text-field dense outlined @keyup.enter="onTrack" id="selectedpassword" class="custom-text-field"
                      required :color="textFieldColor" @click="changeColor" v-model="selectedPassword"
                      label="Enter Password" name="password" prepend-icon="mdi-lock" persistent-placeholder
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword">
                    </v-text-field>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="onTrack" class="white--text" color="#00897b">Track</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- Footer with Links -->
      <footer>
        <div class="footer-links">
          <a href="https://alcobrakeadmin.com/fleet/termsandconditions" >Terms and Conditions</a> |
          <a href="https://alcobrakeadmin.com/fleet/privacypolicy">Privacy Policy</a> |
          <a href="https://alcobrakeadmin.com/fleet/shippingpolicy">Shipping Policy</a> |
          <a href="https://alcobrakeadmin.com/fleet/contactus">Contact Us</a> |
          <a href="https://alcobrakeadmin.com/fleet/cancellationandrefund">Cancellation and Refund Policy</a>
          <div class="copyright">
            &copy; 2025 Alcobrake Private Limited. All Rights Reserved.
          </div>
        </div>
      </footer>

      <!-- Dialogs (Modals) for Each Link -->
      <div v-if="activeDialog" class="dialog-overlay">
        <div class="dialog-content">
          <h2>{{ dialogTitle }}</h2>
          <p v-html="dialogContent"></p> <!-- Use v-html to render HTML content -->
          <button @click="closeDialog">Close</button>
        </div>
      </div>
      </v-container>

      
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      // fleetuser: JSON.parse(localStorage.getItem("fleetuser")),
      fleetuser: null,  // Store the user data after fetching it from localStorage
      showPassword: false,
      textFieldColor: '#009688',
      selectedEmail: null,
      selectedPassword: null,
      activeDialog: null, // Tracks which dialog is open
      dialogTitle: "", // Title of the dialog
      dialogContent: "", // Content of the dialog
    };
  },
  created() {
    // Ensure the label behaves correctly on autofill
    console.log("Stored fleetuser:", this.fleetuser);
    if (this.selectedEmail || this.selectedPassword) {
      this.$nextTick(() => {
        this.selectedEmail = this.selectedEmail || '';
        this.selectedPassword = this.selectedPassword || '';
      });
    }
    this.fetchUserData(); 
  },
  // created() {
  //   this.fetchUserData();  // Call the method to fetch user data on component creation
  // },
  methods: {
       // Method to fetch data from localStorage
       async fetchUserData() {
      try {
        const res = localStorage.getItem('fleetuser');
        if (res) {
          const stdata = JSON.parse(res);
          this.fleetuser = stdata;  // Store the fetched data in fleetuser
          console.log('Fetched User Data:', this.fleetuser);  // Log it in the console
        } else {
          console.log('No data found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    changeColor() {
      this.textFieldColor = '#009688';
    },
    onTrack() {
      if (this.selectedEmail != '' && this.selectedPassword != '') {
        var email = this.selectedEmail;
        var password = this.selectedPassword;
        this.$store.dispatch("authentication/fleetlogin", {
          email,
          password
        });
      }
    },
    openDialog(type) {
  switch (type) {
    case "terms":
      this.dialogTitle = "Terms and Conditions";
      this.dialogContent =
        `Welcome to Alcobrake. By purchasing our products, you agree to the following terms and conditions:

- Alcobrake sells Breathalyzers and Interlock devices exclusively through offline channels.
- All sales are final. We do not offer returns, exchanges, or refunds.
- Our products are intended for professional and legal use only. Alcobrake is not responsible for misuse or unauthorized modifications.
- Prices and product availability are subject to change without prior notice.`;
      break;

    case "privacy":
      this.dialogTitle = "Privacy Policy";
      this.dialogContent =
        `At Alcobrake, we value your privacy. This policy explains how we handle your personal information:

- We collect only necessary customer details for offline transactions and support.
- Your information is never shared, sold, or disclosed to third parties except as required by law.
- We may use your contact details to provide support, product updates, and service-related communication.
- We ensure reasonable security measures to protect your data from unauthorized access.
- If you have any concerns about your privacy, please contact us at <a href="mailto:saireddy@alcobrake.com">saireddy@alcobrake.com</a>.`;
      break;

    case "shipping":
      this.dialogTitle = "Shipping Policy";
      this.dialogContent =
        `Since Alcobrake operates solely through offline sales, the following shipping terms apply:

- We do not process online orders or deliveries.
- Products are directly handed over to customers upon purchase.
- For bulk or special orders, delivery arrangements can be discussed with our sales team.
- Customers are responsible for picking up their products from designated locations as agreed during purchase.`;
      break;

    case "contact":
      this.dialogTitle = "Contact Us";
      this.dialogContent =
        `You may contact us using the information below:

Merchant Legal entity name: Alcobrake Private Limited
Registered Address: Fifth Floor of Ruby Tower, H. No. 198/9/3/4/17 & 18, Silicon Valley, Madhapur, Hi-tech City Hyderabad TELANGANA 500034
Operational Address: Fifth Floor of Ruby Tower, H. No. 198/9/3/4/17 & 18, Silicon Valley, Madhapur, Hi-tech City Hyderabad TELANGANA 500034
Telephone No: 8977285706
E-Mail ID: <a href="mailto:saireddy@alcobrake.com">saireddy@alcobrake.com</a>`;
      break;

    case "cancellation":
      this.dialogTitle = "Cancellation and Refund Policy";
      this.dialogContent =
        `- Alcobrake does not accept cancellations or provide refunds as all sales are made offline and finalized at the time of purchase.
- We ensure thorough product demonstration and verification before finalizing any sale.
- In case of defective or damaged products, please contact us immediately for assistance.`;
      break;
  }
  this.activeDialog = type; // Open the dialog
},
    closeDialog() {
      this.activeDialog = null; // Close the dialog
    },
  }
};
</script>

<style>
.mainbody {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, #26a69a, #b2dfdb, #009688);
  overflow: hidden;
}

.multi-color-toolbar {
  background: linear-gradient(to right, #e4e9ec, #80cbc4);
  color: white;
}

.custom-text-field .v-input__control {
  margin-top: 0;
}

footer {
  position: absolute;
  bottom: 5px; /* Adjust this value to move the footer up or down */
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;

}

.footer-links {
  font-size: 14px;
  /* Reduced font size */
}

.footer-links a {
  text-decoration: underline;
  /* Underline the links */
  color: black !important;
  /* Ensure the default color is black */
  margin: 0 10px;
}

.footer-links a:hover {
  color: #0056b3 !important;
  /* Hover color */
}

/* Dialog (Modal) Styling */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background: white;
  padding: 24px;
  border-radius: 10px;
  width: 700px; /* Fixed width */
  height: 400px; /* Fixed height */
  text-align: left; /* Align text to the left for professionalism */
  overflow-y: auto; /* Scroll if content exceeds the height */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.dialog-content h2 {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.dialog-content p {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1.6;
  color: #333;
  white-space: pre-line; /* Ensures line breaks are preserved */
}

.dialog-content button {
  display: block;
  width: 100px;
  margin: 20px auto 0;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.dialog-content button:hover {
  background: #0056b3;
}

.dialog-content a {
  color: #007bff; /* Link color */
  text-decoration: underline; /* Underline the link */
}

.dialog-content a:hover {
  color: #0056b3; /* Hover color */
}

.copyright {
  margin-top: 10px;
  font-size: 12px;
  color: #6c757d;
}
</style>
<template>
  <v-container class="pa-4 overflow-auto" style="max-height: 80vh;">
    <v-card elevation="4" class="pa-2">
      <!-- Driver Monitoring System Header -->
      <v-card-title class="text-h5 font-weight-bold text-primary">
        Driver Monitoring System
      </v-card-title>
      <v-divider></v-divider>

      <v-row>
        <!-- Main Content Area -->
        <v-col cols="12" sm="9">
          <!-- Loader -->
          <v-row justify="center" v-if="loading">
            <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
          </v-row>

          <!-- No Data Message -->
          <v-row v-else-if="filteredImageData.length === 0" justify="center">
            <v-alert type="info" outlined class="mt-4">🚫 No Data Found</v-alert>
          </v-row>

          <!-- Images Display -->
          <v-tabs v-else v-model="tab" show-arrows center-active background-color="primary" dark>
            <v-tab v-for="(dateData, index) in filteredImageData" :key="index">{{ dateData.date }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(dateData, index) in filteredImageData" :key="index">
              <v-card flat>
                <v-card-title class="text-h6">Date: {{ dateData.date }}</v-card-title>
                <v-divider></v-divider>
                <v-expansion-panels multiple>
                  <v-expansion-panel v-for="(hourData, hIndex) in dateData.hours" :key="hIndex">
                    <v-expansion-panel-header>
                      <v-icon left>mdi-clock-time-four-outline</v-icon> Hour: {{ hourData.hour }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col v-for="(image, imgIndex) in hourData.images" :key="imgIndex" cols="12" sm="6" md="4">
                          <v-card elevation="3" class="pa-2">
                            <v-img :src="'https://robopower.xyz/' + image.image" max-width="100%" height="200" contain></v-img>
                            <v-card-text>
                              <v-chip-group column>
                                <v-chip v-for="(t, index) in image.type.split(',').map(t => t.trim())" :key="index"
                                  color="primary" text-color="white" class="ma-1">
                                  {{ typeLabels[t] || 'Unknown' }}
                                </v-chip>
                              </v-chip-group>
                              <p class="text-caption">📅 {{ image.timestamp }}</p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>

        <!-- Right Side Panel for Filters -->
        <v-col v-if="filteredImageData.length > 0" cols="12" sm="3">
          <v-card class="pa-2" elevation="2">
            <v-card-title class="text-h6">Filters</v-card-title>
            <v-divider></v-divider>
            <!-- Date Picker -->
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="selectedDate" label="Select Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="selectedDate" @input="filterByDate"></v-date-picker>
            </v-menu>
            <v-btn class="mt-2" block color="error" @click="clearFilter">Clear Filter</v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
export default {
  props:['data'],
  data() {
    return {
      tab: 0,
      imageData: [],
      filteredImageData: [],
      loading: false, // Loader State
      menu: false,
      selectedDate: "",
      typeLabels: {
        0: "Drowsy",
        1: "Eyes Closed",
        2: "Yawning Detected",
        3: "Phone Usage Detected",
        4: "Cigarette Detected",
        5: "Distraction: Looking Left",
        6: "Distraction: Looking Right",
        7: "Distraction: Looking Up",
        8: "Distraction: Looking Down",
        9: "Blow Begin",
        10: "Blow Fail",
        11: "Test Pass",
        12: "Test Fail",
      },
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    async onLoad() {
      this.loading = true; // Start Loader
      console.log(this.data)
      try {
        let data = { devid:this.data.Vehicle.cbid };
        let response = await Vue.axios.post(
          "https://robopower.xyz/ai/facedetection/getdistractedimages",
          JSON.stringify(data)
        );
        if (response.data && response.data.length > 0) {
          this.imageData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          this.filteredImageData = this.imageData;
        } else {
          this.imageData = [];
          this.filteredImageData = [];
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.imageData = [];
        this.filteredImageData = [];
      }
      this.loading = false; // Stop Loader
    },
    filterByDate() {
      if (this.selectedDate) {
        this.filteredImageData = this.imageData.filter((data) => data.date === this.selectedDate);
      } else {
        this.filteredImageData = this.imageData;
      }
    },
    clearFilter() {
      this.selectedDate = "";
      this.filteredImageData = this.imageData;
    },
  },
};
</script>

<style scoped>
.v-container {
  background-color: #f5f5f5;
}

.v-card {
  border-radius: 12px;
}

.v-btn {
  text-transform: none;
}
</style>

<template>
  <v-container class="customer-container">
    <v-card class="pa-5 customer-card" elevation="4">
      <v-row justify="center">
        <v-col cols="12" class="text-center">
         <!-- Profile Avatar -->
<!-- <div class="profile-avatar">
  <img src="../assets/customerprofile.png" alt="Profile Image" />
</div> -->
  <!-- Avatar -->
  <div class="avatar-container">
        <div class="avatar">
          <img src="../assets/customerprofile.png" alt="Customer Avatar" />
        </div>
        </div>

        </v-col>
        <v-col cols="12" class="text-center">
          <!-- Customer Name -->
          <h2 class="customer-name">{{ customer.name || 'N/A' }}</h2>
        </v-col>
      </v-row>
      <v-divider class="my-4 divider"></v-divider>
      <!-- Customer Information -->
      <div class="customer-info">
        <div class="info-item">
          <strong>Customer ID :</strong> <span class="data">{{ customer.id || 'N/A' }}</span>
        </div>
        <div class="info-item">
          <strong>Email :</strong> <span class="data">{{ customer.email || 'N/A' }}</span>
        </div>
        <div class="info-item">
          <strong>Contact :</strong> <span class="data">{{ customer.contact || 'N/A' }}</span>
        </div>
        <div class="info-item">
          <strong>GSTIN :</strong> <span class="data">{{ customer.gstin || 'N/A' }}</span>
        </div>
        <div class="info-item">
          <strong>Created :</strong> <span class="data">{{ formatDate(customer.created_at) }}</span>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      fleetuser: JSON.parse(localStorage.getItem("fleetuser")),
      customer: {},
      loading: true,
      error: null
    };
  },
  mounted() {
    console.log(this.fleetuser.customer_id)
    this.fetchCustomerInfo();
  },
  methods: {
    async fetchCustomerInfo() {
      try {
        if(this.fleetuser.customer_id && this.fleetuser.customer_id != ""){
        const response = await axios.get(
          'https://robopower.xyz/v2/indiapayments/getcustomer?cust_id='+this.fleetuser.customer_id
        );
        this.customer = response.data;
        } else{
          alert("User Not Subscribed")
        }
      } catch (err) {
        this.error = 'Error fetching customer details.';
      } finally {
        this.loading = false;
      }
    },
    formatDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
};
</script>

<style scoped>
/* General Styles */
.customer-container {
  padding: 30px;
}

.customer-card {
  max-width: 550px;
  margin: 0 auto;
  text-align: center; /* Center-align all text in the card */
}


.customer-name {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 16px; /* Add margin below the name */
}


.customer-info {
  font-size: 16px;
  color: black;
  padding: 10px; /* Added padding for better spacing */
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px; /* Uniform spacing between items */
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9; /* Light background for better visibility */
  padding: 10px;
  border-radius: 8px; /* Rounded corners */
}

.info-item strong {
  color: #333;
  min-width: 120px; /* Ensures labels are aligned */
}

.data {
  color: #555;
  font-weight: 500;
}

.divider {
  border-color: black;
  margin: 24px auto; /* Increased margin for better spacing */
  width: 80%; /* Adjust width for better alignment */
}

.avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.avatar {
  width: 150px; /* Fixed width for avatar */
  height: 150px; /* Fixed height for avatar */
  border-radius: 50%; /* Make the container circular */
  overflow: hidden; /* Prevent the image from overflowing */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddd; /* Optional: Add a border to the avatar */
}

.avatar img {
 max-width: 100%; /* Ensure the image takes full width of the container */
object-fit: cover; /* Ensures the image covers the container without distortion */
margin-left: auto;
}

@media (max-width: 600px) {
  .avatar {
    /* width: 120px;  */
    /* height: 120px; */
    max-width: 100%;
    /* margin-left: -90%; */
  }
}


@media (max-width: 600px) {
  .divider {
    width: 90%; /* Slightly wider divider for mobile */
  }

  .customer-info {
    font-size: 14px;
    padding: 8px;
  }

  .info-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  }

  .info-item strong {
    min-width: auto;
    display: block;
    margin-bottom: 4px; /* Add spacing for better readability */
  }
}
</style>

<template>
  <v-container>
    <v-card class="pa-5" elevation="2">
      <v-card-title class="text-h5">Terms and Conditions</v-card-title>
      <v-card-text>
        <p>Welcome to Alcobrake. By purchasing our products, you agree to the following terms and conditions:</p>
        <ul>
          <li>Alcobrake sells Breathalyzers and Interlock devices exclusively through offline channels.</li>
          <li>All sales are final. We do not offer returns, exchanges, or refunds.</li>
          <li>Our products are intended for professional and legal use only. Alcobrake is not responsible for misuse or unauthorized modifications.</li>
          <li>Prices and product availability are subject to change without prior notice.</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "TermsAndConditions"
};
</script>

<style scoped>
ul {
  padding-left: 20px;
}
li {
  margin-bottom: 10px;
}
</style>

<template>
  <v-container>
    <v-card class="pa-5" elevation="3">
      <v-card-title class="text-h5 font-weight-bold text-center mb-4">
        Transactions 
      </v-card-title>

      <!-- Loading Spinner -->
      <v-row v-if="loading" class="justify-center">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <h1 class="mt-2">Loading transactions...</h1>
        </v-col>
      </v-row>

      <!-- Filters Section -->
      <v-row v-else class="mb-4">
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="searchQuery"
            label="Search transactions"
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            label="Filter by Status"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="monthFilter"
            :items="monthOptions"
            label="Sort by Date"
            outlined
            dense
            prepend-inner-icon="mdi-calendar"
          ></v-select>
        </v-col>
      </v-row>

      <!-- Transactions Grid -->
      <v-row v-if="!loading">
        <v-col v-for="transaction in filteredTransactions" 
               :key="transaction.id" 
               cols="12" 
               md="6"
               lg="4">
          <v-card class="transaction-card" elevation="2">
            <v-card-title class="d-flex justify-space-between">
              <span class="text-subtitle-1">{{ transaction.customer }}</span>
              <v-chip
                :color="getStatusColor(transaction.status)"
                text-color="white"
                small
              >
                {{ transaction.status }}
              </v-chip>
            </v-card-title>
            
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-truncate">
                      <span class="font-weight-bold">ID:</span> 
                      <span :title="transaction.id">{{ transaction.id }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-bold">Amount:</span> 
                      ₹{{ transaction.amount.toFixed(2) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-bold">Method:</span> 
                      {{ transaction.method }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-weight-medium">Date:</span> 
                      {{ formatDate(transaction.created_at) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Transactions Message -->
      <v-row v-if="!loading && transactions.length === 0">
        <v-col cols="12" class="text-center">
          <v-alert type="info">
            No transactions found.
          </v-alert>
        </v-col>
      </v-row>

      <!-- No Results Message -->
      <v-row v-if="!loading && filteredTransactions.length === 0 && transactions.length > 0">
        <v-col cols="12" class="text-center">
          <v-alert type="info">
            No transactions found matching your filters.
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TransactionDashboard',
  
  data() {
    return {
      fleetuser: JSON.parse(localStorage.getItem("fleetuser")),
      transactions: [], // Initialize as empty array
      searchQuery: '',
      statusFilter: 'All',
      monthFilter: 'All',
      statusOptions: ['All', 'Success', 'Pending', 'Failed'],
      monthOptions: ['All', 'Newest First', 'Oldest First'],
      loading: false // Add loading state
    }
  },

  computed: {
    filteredTransactions() {
      let filtered = this.transactions.filter(transaction => {
        const searchMatch = 
          transaction.id.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          transaction.customer.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          transaction.method.toLowerCase().includes(this.searchQuery.toLowerCase());

        const statusMatch = 
          this.statusFilter === 'All' || 
          transaction.status === this.statusFilter;

        return searchMatch && statusMatch;
      });

      // Sort by date
      if (this.monthFilter === "Newest First") {
        filtered = filtered.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } else if (this.monthFilter === "Oldest First") {
        filtered = filtered.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else {
        // Default sorting (ascending)
        filtered = filtered.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      }

      return filtered;
    }
  },

  methods: {
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = String(d.getFullYear()).slice(-2);
      return `${day}/${month}/${year}`;
    },

    getStatusColor(status) {
      const colors = {
        Success: 'success',
        Pending: 'warning',
        Failed: 'error'
      };
      return colors[status] || 'grey';
    },

    async fetchTransactions() {
      this.loading = true; // Start loading
      try {
        if(this.fleetuser.customer_id && this.fleetuser.customer_id != ""){
        const response = await axios.get(
          `https://robopower.xyz/v2/indiapayments/getcustomerpayments?cust_id=${this.fleetuser.customer_id}`
        );
        console.log('API Response:', response.data); // Log the response

        // Check if the response contains the expected data
        if (response.data && Array.isArray(response.data.items)) {
          if (response.data.items.length > 0) {
            this.transactions = response.data.items.map(transaction => ({
              id: transaction.id,
              amount: transaction.amount,
              currency: transaction.currency,
              method: transaction.method,
              status: transaction.status,
              created_at: transaction.created_at,
              customer: transaction.customer
            }));
          } else {
            console.warn('No transactions found in the API response.');
            this.transactions = []; // Ensure transactions array is empty
          }
        } else {
          console.error('Invalid API response format:', response.data);
        }
      }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        this.loading = false; // Stop loading
      }
    }
  },

  mounted() {
    // Fetch transactions when the component is mounted
    this.fetchTransactions();
  }
}
</script>

<style scoped>
.transaction-card {
  background: #f9f9f9;
  transition: transform 0.2s;
}

.transaction-card:hover {
  transform: translateY(-2px);
}

.v-list-item {
  min-height: 32px !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
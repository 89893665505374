<template>
  <v-app v-if="fullclients.length >0">
    <v-app v-if="grantAccess">
      <!-- <div class="scroll-container"> -->
    <div class="scroll-text">
      {{ warningmessage }}
    <!-- </div> -->
  </div>
  <v-dialog v-model="editDialog" max-width="600px">
  <v-card class="pa-4">
    <v-card-title class="headline text-center d-flex justify-center" style="background-color: #1976D2; color: white; padding: 12px; font-weight: bold;">
      Edit Vehicle Details
    </v-card-title>
    <v-card-text>
      <v-container fluid style="margin-top:20px">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.client" 
              label="Client *" 
              outlined 
              dense 
              required
               :rules="[v => !!v || 'This field is required']"
              class="custom-input" 
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.clientphone1" 
              label="Client Phone 1" 
              outlined 
              dense 
              :rules="phoneRules"
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.clientphone2" 
              label="Client Phone 2" 
              outlined 
              dense 
              :rules="phoneRules"
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.email" 
              label="Email" 
              type="email" 
              outlined 
              dense 
              :rules="emailRules"
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.dob" 
              label="Date of Birth" 
              type="date" 
              outlined 
              dense 
              required
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.clientAddress" 
              label="Client Address" 
              outlined 
              dense 
              required
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.city" 
              label="City" 
              outlined 
              dense 
              required
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.state" 
              label="State" 
              outlined 
              dense 
              required
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.zipcode" 
              label="Zip Code" 
              type="number" 
              outlined 
              dense 
              required
              class="custom-input"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field 
              v-model="editedItem.regnum" 
              label="Registration Number *" 
              outlined 
              dense 
              required
               :rules="[v => !!v || 'This field is required']"
              class="custom-input"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
     <!-- Buttons Section -->
     <v-card-actions class="justify-center pa-4">
      <v-btn color="red darken-1" class="white--text px-4" @click="closeDialog">
        Cancel
      </v-btn>
      <v-btn color="green darken-1" class="white--text px-4 ml-3" @click="saveEdit">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
    <div class="full-screen-container" v-if="!isMobile()">

      <!-- Events Panel -->
    <transition name="slide-fade">
      
      <div v-if="selectedVehicleevents" class="events-panel">
        <v-dialog width="400px" v-model="dialog10">
              <v-card text style="border-radius: 20px">
                <v-toolbar color="#00A3A3" style="border-radius: 20px; color: white">
                  <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
                    Please Choose The Format
                  </v-toolbar-title>
                  <v-toolbar-title v-else style="font-size: 15px">
                    Please Choose The Format
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-layout justify-end>
                    <v-btn color="black" @click="close10" class="sm-0" style="border-radius: 20px"><v-icon
                        color="white">
                        mdi-close</v-icon>
                    </v-btn>
                  </v-layout>
                </v-toolbar>
                <div align="center">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-4" tile color="green" v-bind="attrs" v-on="on" @click="csvdownload"
                        style="border-radius: 10px; color: white">
                        CSV
                        <v-icon right> mdi-download </v-icon>
                      </v-btn>
                    </template>

                    <span>split data with delimiter semi-colon(;)</span>
                  </v-tooltip>
                </div>
                <div align="center">
                  <v-btn class="mt-2 mb-2" tile color="#334c3e" @click="textdownload"
                    style="border-radius: 10px; color: white">
                    TEXT Document
                    <v-icon right> mdi-download </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
        <v-row>
        <v-btn @click="closeEvents" class="primary close-btn"  style="margin-top: 2%;margin-left: 2%">Close Events</v-btn>
        <v-spacer></v-spacer>
        
        <!-- <v-btn @click="downloadEvents" class="warning close-btn"  style="margin-top: 2%;margin-right: 4%">Log Download</v-btn> -->
        <template>
  <v-btn 
    @click="downloadEvents" 
    :loading="isDownloading"
    :disabled="isDownloading"
    class="warning close-btn"  
    style="margin-top: 2%; margin-right: 4%"
  >
    <template v-if="!isDownloading">
     Log Download
    </template>
    <template v-else>
      <v-progress-circular
        indeterminate
        size="20"
        color="white"
      ></v-progress-circular>
    </template>
  </v-btn>
</template>

      </v-row>
        <div class="events-content">
          <AlcoholRecordsChart :events="selectedVehicleevents" :data="singleVehicle" ref="chartContent"/>
        </div>
      </div>
    </transition>
    <!-- Display Map for Selected Vehicle -->
    <transition name="slide-fade">
      <div v-if="selectedVehiclemap" class="map-panel">
  <div style="border-bottom: 2px dashed black;display:inline-flex;margin-top:3%;">
    <v-form ref="datetimeform" v-model="valid3" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
      style="margin-left:20px; width: 100%;">
      <v-row align="center">
        <h3>{{ ping[0].Vehicle.regnum }} </h3>
      </v-row>
      <v-row align="center">
        <!-- Align From and To Date to the left -->
        <!-- <v-col cols="12" md="3" lg="3">
          <v-datetime-picker label="From Date" ref="fromdate" v-model="fromdate" @input="changeFdate()"></v-datetime-picker>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <v-datetime-picker label="To Date" v-model="todate" ref="todate" :min="minToDate" @input="changeTdate()"></v-datetime-picker>
        </v-col> -->
        <!-- <v-row style="margin-top:10px"> -->
          <v-col cols="12" md="2" lg="2">
<v-text-field label="FromDate" hint=""
  persistent-hint @click="ondialog()" v-model="fromdate"></v-text-field>
  </v-col>
  <v-divider class="mx-2" inset vertical></v-divider>
  <v-col cols="12" md="2" lg="2">
  <v-text-field label="ToDate"
  persistent-hint @click="ontodialog()" v-model="todate"></v-text-field>
</v-col>
<!-- </v-row> -->
<v-dialog v-model="fromdatedialog" max-width="320" max-height="700" persistent>
                                  
                                  <template>
                                    <v-container>
                                <v-row justify="space-around">
                                  <v-col v-if="date = true">
                                  <v-date-picker v-model="fromonlydate" no-title style="height: 370px; max-width: 500px;"></v-date-picker>
                                  </v-col>
                                  <v-col v-if="fromonlydate != ''">
                                    <v-time-picker v-model="fromonlytime" no-title hide-header></v-time-picker>
                                    <template >
                                        <v-row style="margin-top:10px">
                                      <v-btn @click="onClose()">
                                        cancel
                                      </v-btn>
                                      <v-spacer></v-spacer>
                                      <v-btn @click="SelectedfromDateTime()">
                                        Ok
                                      </v-btn>
                                    </v-row>
                                  </template>
                                  </v-col>
                                </v-row>
                              </v-container>
                             
                                  </template>
                                      
                                </v-dialog>

                                <v-dialog v-model="fromtodatedialog" max-width="320" max-height="700" persistent>
                                                              
                                                              <template>
                                                                <v-container>
                                                            <v-row justify="space-around">
                                                              <v-col>
                                                              <v-date-picker v-model="fromtoonlydate" no-title style="height: 370px; max-width: 500px;"></v-date-picker>
                                                              </v-col>
                                                              <v-col v-if="fromtoonlydate != ''">
                                                                <v-time-picker v-model="fromtoonlytime" no-title hide-header></v-time-picker>
                                                                <template >
                                                                    <v-row style="margin-top:10px">
                                                                  <v-btn @click="ontoClose()">
                                                                    cancel
                                                                  </v-btn>
                                                                  <v-spacer></v-spacer>
                                                                  <v-btn @click="SelectedtoDateTime()">
                                                                    Ok
                                                                  </v-btn>
                                                                </v-row>
                                                              </template>
                                                              </v-col>
                                                            </v-row>
                                                          </v-container>
                                                         
                                                              </template>
                                                                  
                                                            </v-dialog>

        <!-- Center Play and Stop buttons -->
        <v-col cols="8" md="2" lg="2" class="text-center">
          <v-btn @click="play" style="background: green; color: white; border-radius: 20px">
            {{ isplay ? "Replay" : "Play" }}
          </v-btn>
        </v-col>
        <v-col cols="8" md="2" lg="2" class="text-center">
          <v-btn @click="stop" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
            style="background: #ff3d41; color: white; border-radius: 20px">
            Stop
          </v-btn>
        </v-col>

        <!-- Right align Close Map button -->
        <v-col cols="8" md="2" lg="2" class="text-right">
          <v-btn @click="closeMap" class="close-btn" color="primary">Close Map</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- Mobile Layout -->
    <v-form v-else v-model="valid3" ref="datetimeform" style="width: 100%;">
      <v-layout style="margin-top: 20px">
        <v-datetime-picker label="From Date" v-model="fromdate" @input="changeFdate()"></v-datetime-picker>
        &nbsp;
        <v-datetime-picker label="To Date" v-model="todate" ref="todate" @input="changeTdate()"></v-datetime-picker>
      </v-layout>

      <v-layout justify-center>
        <v-icon @click="play" size="30" color="#ffa726">
          {{ isplay ? "mdi-play-pause" : "mdi-play-circle-outline" }}
        </v-icon>
        &nbsp;
        <v-icon @click="stop" size="30" left color="#ffa726">mdi-stop-circle-outline</v-icon>
      </v-layout>

      <!-- Right align Close Map button -->
      <v-layout justify-end>
        <v-btn @click="closeMap" class="close-btn" color="primary">Close Map</v-btn>
      </v-layout>
    </v-form>
  </div>
  <div v-if="!isplay" :id="`map-${selectedVehiclemap.gtime}`" class="map"></div>
  <Routeplay v-else :data="ping" :isPlay="isplay"  :starttime="fromdate"
                        :endtime="todate" @routeclosed="routeclosed"></Routeplay>
</div>

        </transition>
        <transition name="slide-fade">
      <div v-if="vehicleSettings" class="map-panel">
  <!-- <h1>Settings</h1> -->
  <v-btn @click="closeSettings" class="primary close-btn" style="margin-top: 5%;margin-left: 2%">Close</v-btn>
  <SettingsTrack ref="settingstracking" :data.sync="vehicleSettings" />
</div>

        </transition>
        <transition name="slide-fade">
      <div v-if="facerecognitionSettings" class="map-panel">
  <!-- <h1>Settings</h1> -->
  <v-btn @click="closeFacerecognition" class="primary close-btn" style="margin-top: 5%;margin-left: 2%">Close</v-btn>
  <Facerecognition ref="settingstracking" :data.sync="facerecognitionSettings" />
</div>

        </transition>
      <div class="container-fluid" style="background-color: aliceblue;">
        <!-- <v-layout > -->
          <v-dialog v-model="allopdialog" width="800px">
            <v-toolbar text color="#b2dfdb" class="" dark dense>
              <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg" style="color:#00897b">
                All Operations
              </v-toolbar-title>
              <v-layout justify-end>
                <v-btn color="black" small @click="allopdialogclose" dark style="border-radius:20px;"><v-icon
                    color="white">
                    mdi-close
                  </v-icon></v-btn>
              </v-layout>
            </v-toolbar>
            <lastoperations ref="optracking" :data.sync="devdata" />
            <v-toolbar text color="#b2dfdb" class="" dark dense style="margin-top:10%;height: 30px;;"></v-toolbar>
          </v-dialog>
          <v-dialog v-model="eventmapdialog" width="600px">
            <v-toolbar text color="rgb(224, 105, 139)" dark>
              <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"> Location</v-toolbar-title>
              <v-layout justify-end>
                <v-btn color="black" small @click="eventTrackclose" dark style="border-radius:20px;"><v-icon
                    color="white">
                    mdi-close
                  </v-icon></v-btn>
              </v-layout>
            </v-toolbar>
            <EventTrack ref="eventtracking" :data.sync="eventdata" />
          </v-dialog>
          <v-dialog v-model="imgdialog" width="500px" @click:outside="imgclose">
            <v-toolbar text color="#f3e5f5" class="" dark dense>
              <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"> <span
                  style="font-size:15px;color: #8e24aa;">TIME:{{ rtime }}</span>&nbsp;&nbsp;<span
                  style="font-size:15px;color: #8e24aa;">BAC:
                  {{ bacvalue }}</span></v-toolbar-title>
              <v-toolbar-title v-else> <span style="font-size:11px;">TIME:{{ rtime }}</span>&nbsp;<span
                  style="font-size:11px;color: #8e24aa;">BAC: {{ bacvalue }}</span></v-toolbar-title>
              <v-layout justify-end>
                <v-icon v-if="imageBytes != ''" @click="downloadImage(currentevent)" color="white" class="mr-4"
                  style="background:#8e24aa;padding:4px;border-radius:12px">mdi-download</v-icon>

                <v-icon v-if="imageBytes != ''" @click="fullscreen()" color="white" class="mr-4"
                  style="background:#8e24aa;;padding:4px;border-radius:12px">mdi-fullscreen</v-icon>

                <v-btn color="#8e24aa" small @click="imgclose" dark style="border-radius:30px;"><v-icon color="white">
                    mdi-close
                  </v-icon></v-btn>
              </v-layout>
            </v-toolbar>
            <v-img id="my-video" :style="result == 'FAIL' ? 'border: 4px solid red;' : 'border: 3px solid green;'"
              v-bind:src="'data:image/jpeg;base64,' + imageBytes"
              v-if="imageBytes != '' && imageBytes != 'noimagefound'" />
            <v-card-text v-else class=text-center>
              <h2 style="background:#8e24aa;color:white;border-radius:20px;margin-bottom: 2%;">{{ imageloadingtext }}
              </h2>
            </v-card-text>
            <v-toolbar text color="#ba68c8" class="" dark dense style="margin-top:-2%">
              <v-toolbar-title>
                <span style="font-size:16px;">RESULT: {{ result }}</span>
              </v-toolbar-title>
            </v-toolbar>
          </v-dialog>
          <v-dialog v-model="prfdialog" max-width="500px">
            <v-card style="border-radius:20px;overflow: hidden;">
              <v-toolbar  dark dense style="border-radius:20px;background: linear-gradient(135deg, #74ebd5, #acb6e5);">
                <v-toolbar-title >Profile</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="prfdialog = false" style="background:black;border-radius:20px">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-col>
                <v-row>
                  <v-col>
                    <div class="d-flex justify-center">
                      <v-img src="https://www.alcobrake.com/assets/images/old_logo.png" max-width="200"></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="justify-center" v-if="profiledata != null">
                  <v-col class="col-md-10">
                    <v-card style="border-radius:20px;background:#cccccc;overflow: hidden;">
                      <v-card-text class="p-right"><b class="p-left"><v-icon class="off-icon">mdi-account</v-icon>Full
                          Name
                          :</b> {{ profiledata.dealerName }}</v-card-text>
                      <v-card-text class="p-right"><b class="p-left"><v-icon
                            class="off-icon">mdi-email-outline</v-icon>E-mail :</b>{{ profiledata.dealerEmail
                        }}</v-card-text>
                      <v-card-text class="p-right"><b class="p-left"><v-icon
                            class="off-icon">mdi-cellphone-iphone</v-icon>Mobile Number :</b>
                        {{ profiledata.dealerMobile1 }}</v-card-text>
                      <v-card-text class="p-right"><b class="p-left"><v-icon
                            class="off-icon">mdi-account-multiple</v-icon>Access type :</b>
                        {{ profiledata.accesslevel }}</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <span v-else>Profile Data Not Fount</span>
              </v-col>
            </v-card>
          </v-dialog>


          <v-card
          class="full-screen-container"
            style="width: 95%;margin-left: 2.5%;background-color: aliceblue;margin-top: 1%;margin-bottom: 1%;overflow: hidden">
            <v-row style="width: 97%; margin-left: 1.5%;margin-top: 1%;">
              <v-toolbar>
                <v-img src='../../public/old_logo.png' max-width="140" style="margin-top:1" />
                <v-row>
                  <v-col cols="4" style="margin-top: 0.5%">
                    <div class="grid">
                      <div class="grid-item">
                        <b style="color: #f57c00;">Moving</b>
                        <b>-{{ moving }}</b>
                      </div>
                      <div class="grid-item">
                        <b style="color:#00c853;">Offline</b>
                        <b>-{{ offline }}</b>
                      </div>
                      <div class="grid-item">
                        <b style="color:#f57c00 ;">Online</b>
                        <b>-{{ online }}</b>
                      </div>
                      <div class="grid-item">
                        <b style="color:#00c853 ;">Pass</b>
                        <b>-{{ passes }}</b>
                      </div>
                      <div class="grid-item">
                        <b style="color: #f57c00;">Fails</b>
                        <b>-{{ fails }}</b>
                      </div>
                      <div class="grid-item">
                        <b style="color: #00c853;">ByPass</b>
                        <b>-{{ bypass }}</b>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" hide-details
                      style="margin-top: 5%;"></v-text-field>
                  </v-col>
                  
                  <v-col cols="1">
                    <div class="d-flex" style="justify-content: space-around;margin-top: 20%;margin-left: 10%;">
                      <div
                        style="justify-content: space-between;border: 2px solid black;display: inline-flex;border-radius: 10px;height: 65%;">
                        <v-icon dark size="30px" style="color:#85bb65;" @click="clearSearch">
                          mdi-refresh
                        </v-icon>
                      </div>
                      &nbsp; &nbsp;
                      <div style="border: 2px solid black;display: inline-flex;border-radius: 10px;height: 65%;">
                        <v-menu origin="top right" transition="scale-transition">
                          <template v-slot:activator="{ attrs, on }">
                            <v-icon v-on="on" size="30px" v-bind="attrs" style="color:#8b0000;">
                              mdi-filter-outline</v-icon>
                          </template>
                          <v-list style="margin-top: 30%;">
                            <v-list-item>
                              <v-btn class="ml-2" color="#7dbab6" style="width: 90%;" @click="filteredData('fail')">
                                DRUNK
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn class="ml-2" style="width: 90%;" color="#D45C6C" @click="filteredData('bypass')">
                                BYPASS
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn class="ml-2" style="width: 90%;" color="#88C68E"
                                @click="filteredData('overspeed')">
                                OVERSPEED
                              </v-btn>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item style="justify-content: center;text-align: center;">
                              <p style="color: dodgerblue;margin-top:5%" @click="isFiltered = false">ClearFilters</p>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      &nbsp; &nbsp;
                    </div>
                  </v-col>
                  <v-col cols="3" align="right" style="margin-top:1%">
                    <span style="font-size:20px;color:green;">{{ fleetuser.userName }}</span>
                  </v-col>
                  <v-col cols="1" style="margin-left: -1%;">
                    <div style="border: 2px solid black;margin-top: 9%;display: inline-flex;border-radius: 10px;">
                      <v-menu bottom left offset-y origin="top right" transition="scale-transition">

                        <template v-slot:activator="{ attrs, on }">
                          <v-btn text color="#99FFCC" class="font-weight-bold" v-bind="attrs" v-on="on"
                            max-width="140px">
                            <v-icon v-on="on" color="black" size="30px">mdi-account-circle</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-btn class="ml-2" style="width: 90%;" @click="profiledealer()" color="#527c91">
                              <v-icon
                                style="background-color:black;border-radius: 30px;color: white;">mdi-account</v-icon>
                              &nbsp;
                              Profile
                            </v-btn>
                          </v-list-item>
                          <v-list-item>
                            <v-btn class="ml-2" style="width: 90%;" @click="onLogout" color="#D45C6C">
                              <v-img src="../assets/logout.png" max-width="28" max-height="26"
                                style="background-color: #8b0000;border-radius: 30px;" />
                              &nbsp; Logout
                            </v-btn>
                          </v-list-item>

                          <v-list-item>
                            <v-btn
                              class="ml-2"
                              style="width: 90%;"
                              @click="servicesPage"
                              color="primary"
                            >
                              <!-- <v-img
                                src="../assets/logout.png"
                                max-width="28"
                                max-height="26"
                                style="background-color: green; border-radius: 30px;"
                              /> -->
                              <!-- &nbsp; Services -->
                              <v-icon size="24" style="background-color: green; border-radius: 30px;">
                                     {{ servicesDialog ? 'mdi-home' : 'mdi-tools' }}
                               </v-icon>
                               &nbsp; {{ servicesDialog ? 'Home' : 'Services' }}
                              <!-- &nbsp; {{ showServices ? 'Home' : 'Services' }} -->
                            </v-btn>
                          </v-list-item>



                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-toolbar>
            </v-row>

            <transition name="slide-fade">
              <!-- Services Content (Conditionally Rendered) -->
              <!-- Web View: Services Section -->
<!-- Web View -->
<!-- Web View -->
<v-row v-if="servicesDialog" style="width: 95%; margin-left: 2.5%; margin-top: 2%;">
    <div class="fill-width">
      <v-card flat class="fill-width">
        <!-- Tabs for Services -->
        <v-tabs
          v-model="activeTab"
          grow
          class="fill-width"
          background-color="primary"
          dark
        >
          <v-tab class="flex-grow-1" @click="updateActiveComponent('ServiceCustomerInfo')">
            <v-icon left>mdi-account</v-icon> Customer Info
          </v-tab>
          <v-tab class="flex-grow-1" @click="updateActiveComponent('ServiceInvoices')">
            <v-icon left>mdi-file-document</v-icon> Invoice
          </v-tab>
          <v-tab class="flex-grow-1" @click="updateActiveComponent('ServiceTransactions')">
            <v-icon left>mdi-swap-horizontal</v-icon> Transaction
          </v-tab>
          <v-tab class="flex-grow-1" @click="updateActiveComponent('ServiceAppointments')">
            <v-icon left>mdi-calendar-check</v-icon> Appointments
          </v-tab>
        </v-tabs>
      </v-card>

      <!-- Dynamic Component -->
      <v-col cols="12" class="pa-0">
        <keep-alive>
          <component :is="activeComponent" />
        </keep-alive>
      </v-col>
    </div>
  </v-row>
   </transition>
           
            <v-card
              style="width:97%;margin-left: 1.5%;background-color: aliceblue;margin-top:1.5%;margin-bottom: 5%;overflow: hidden">
              <v-row style="margin-top: -1%;" v-if="!servicesDialog">
                <v-col class="col-sm-4">
                  <div class="container-fluid" style="background: linear-gradient(135deg, #74ebd5, #acb6e5);">
                    <v-list  :style="{ maxHeight: screenHeight + 'px', overflowY: 'scroll', overflowX: 'hidden', overflowWrap: 'normal' }">
                      <v-list-item :clickable="false" v-for="(item, i) in resultQuery" :key="i">
                        <v-card style="padding: 2%;width: 100%;margin: 1%;overflow: hidden;">
                          <v-row style="margin-top: -2%;">
                            <v-col class="col-4">
                              <v-img v-if="item.Engine.enginerunstate == 1 && parseFloat(item.Gps.speed) > 0"
                                src='../assets/bus.png' @click="openMap(item)"
                                style="cursor: pointer;width: 50%;margin-left:2%;" />
                              <v-img v-else-if="item.Engine.enginerunstate == 1 && parseFloat(item.Gps.speed) == 0.0"
                                src='../assets/bus-idle.png' @click="openMap(item)"
                                style="cursor: pointer;width: 50%;margin-left:2%;" />
                              <v-img v-else src='../assets/bus-off.png' @click="openMap(item)"
                                style="cursor: pointer;width: 50%;margin-left:2%;" />
                            </v-col>
                            <v-col class="col-4" style="text-align: center;">
                              <span style="font-size:13px;font-weight: 500;color: black;margin-left: 3%;">{{item.Vehicle.regnum}}</span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col align="center" class="col-2">
                              <v-icon style="color: black;" @click="openMap(item)">
                                mdi-crosshairs-gps</v-icon>
                               <!--<v-switch :value="item.Vehicle.issms"  v-model="item.Vehicle.issms" flat label="SMS Alert"
                        @change="issmsvaluechange(item)"></v-switch>-->
                            </v-col>
                          </v-row>
                          <v-row style="margin-bottom:-4%;margin-top: -3%;">
                            <v-col class="col-6" style="text-align: left;">
                              <p style="font-size:12px;font-weight: 600;margin-left: 3%;">{{ item.Vehicle.client }}</p>
                            </v-col>
                            <v-col class="col-6" style="text-align: center;margin-top:-2%;">
                              <v-icon
                                :style="item.Engine.enginerunstate == 0 ? { 'color': 'red' } : { 'color': 'green' }"
                                style="border: 0.5px solid #ab4573;border-radius: 30%;">
                                mdi-engine</v-icon>
                              <span style="font-size:x-small;color:#ab4573;font-weight: bold;margin-left:5%;">{{item.Engine.enginerunstate == "0" || item.Engine.enginerunstate == "" ? "ENGINE OFF" : "ENGINE ON" }}</span>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row>
                            <v-col>
                              <div style="margin-bottom: 4%;margin-top:2%">
                                <Barchart ref="alcoholdata" :devid="item"></Barchart>
                              </div>
                            </v-col>
                          </v-row>

                          <v-divider></v-divider>
                          <v-row>
                            <v-col cols="6">
                              <v-row style="margin-left: 2%;margin-top:2%">
                                <span style="font-size: x-small;font-weight: bold;">Last Action</span>
                              </v-row>
                              <v-row style="margin-left: 2%;">
                                <v-tooltip top>

                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" style="font-size:10px;">{{ getEventtype(item) }}
                                    </span>
                                  </template>
                                  <span style="font-size:12px">{{ item.Event.rtime }}</span>
                                </v-tooltip>
                                <v-icon
                                  style="font-size:small;color:dodgerblue;margin-left:5%;margin-top: -2%;text-decoration: underline;"
                                  @click="allopTrack(item)">View</v-icon>
                              </v-row>
                            </v-col>
                            <v-col cols="6" style="margin-left:-4%">
                              <v-row style="margin-top: 2%;">
                                <SpeedChart ref="speeddata" :devid="item"></SpeedChart>
                              </v-row>
                              <v-row style="margin-right: 1rem;">
                                <v-spacer></v-spacer>
                                <v-icon style="color:#4db6ac;">mdi-av-timer</v-icon>
                                <span style="margin-left: 0.5rem;">{{ Math.round(parseFloat(item.Gps.speed)) != "" ? Math.round(parseFloat(item.Gps.speed)) : 0 }}
                                  kmph</span>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row style="margin-bottom:4%;">
                            <v-col cols="1">
                              <v-icon size="25" @click="onLoadevents(item)" style="color: #c5b350;"
                                >mdi-calendar-month</v-icon>
                            </v-col>
                            <v-col cols="1">
                              <v-icon size="22" @click="openSettings(item)" style="color: #4774e6;"
                                >mdi-cog</v-icon>
                            </v-col>
                            <v-col  cols="2">
                              <v-icon size="22" @click="openFacerecognition(item)" style="color: red;"
                                >mdi-face-recognition</v-icon>
                            </v-col>
                            <v-col cols="2" align="center">
                              <v-tooltip top>

                                <template v-slot:activator="{ on, attrs }">
                                  <v-img v-bind="attrs" v-on="on" max-height="23" max-width="17" style=""
                                    @click="goCommand(item, '84')" src="../assets/Dreger413__1.png"
                                    alt="Image Icon"></v-img>
                                </template>
                                <span style="font-size:12px">Take Test</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="1" style="margin-left:-6%;">
                              <v-tooltip top>

                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" style="color: #d56666;"
                                    @click="goCommand(item, '83')">mdi-camera-enhance</v-icon>
                                </template>
                                <span style="font-size:12px">Take Picture</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip top>

                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" style="color: #74c3a6;"
                                    @click="goCommand(item, '88')">mdi-cursor-pointer</v-icon>
                                </template>
                                <span style="font-size:12px">ByPass</span>
                              </v-tooltip>
                            </v-col>
<v-spacer></v-spacer>
<v-col align="center" class="col-2"> 
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" style="color: #f5a623;" @click="openEditDialog(item)">mdi-pencil</v-icon>
    </template>
    <span>Edit</span>
  </v-tooltip>
</v-col>
                          </v-row>
                          <v-row style="margin-top:-30px">
                            <v-col cols="1">
                            <div class="text-center">
    <div class="text-center">
    <v-rating
      v-model="vehicleRatings[item.Vehicle.cbid]" 
      active-color="blue"
      color="orange-lighten-1"
      half-increments
      length="5"
      readonly
    ></v-rating>
  </div>

  </div>
</v-col>
                          </v-row>
                      
                          <v-divider style="margin-top:2%;"></v-divider>
                          <v-row>
                            <v-col style="margin-top:1%;margin-bottom:-4%;">
                              <p style="font-size:10px;margin-left: 2%;">Last Update
                                :
                                {{ calctime(item.Gps) != 'NA' ? calctime(item.Gps) : '' }}</p>
                            </v-col>
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-col style="margin-top:1%;">
                              <v-btn style=" color: black;margin-left: 35px;background-color:#64b5f6;font-size:11px" width="80" height="30" @click="openSettings(item)">
                                  <span><v-icon style="color: black;" size="16">
  mdi-cog
</v-icon></span>Setting</v-btn>
                            </v-col> -->
                          </v-row>
                          

                        </v-card>
                      </v-list-item>
                      <br><br><br><br><br>
                    </v-list>
                  </div>
                </v-col>
           

                <v-col class="col-sm-8">
                  <div class="container-fluid">
                    <v-toolbar v-if="toolbar" style="position: absolute;z-index: 1;width: 68%;margin-left: -1.4%;">
                      <p style="font-size: 12px;font-weight:bold;text-align: center;margin-top: 20px;color: #5e35b1;">
                        Real Time Location
                        <v-icon class="ml-2" size="30" color="#673ab7">mdi-car-pickup</v-icon>
                      </p>
                      
                      <p></p>
                      <!-- <v-spacer></v-spacer> -->
                      <v-form ref="datetimeform" v-model="valid3"
                        v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                        style="width: 40%;display: inline-flex;margin-top: 2%;">
                        <v-datetime-picker label="From Date" ref="fromdate" v-model="fromdate"
                          @input="changeFdate()"></v-datetime-picker>
                        &nbsp;
                        <v-datetime-picker label="To Date" v-model="todate" ref="todate" :min="minToDate"
                          @input="changeTdate()"></v-datetime-picker>
                      </v-form>
                      <v-form v-else v-model="valid3" ref="datetimeform">
                        <v-layout style="width: 40%;display: inline-flex;margin-top: 2%;">
                          <v-datetime-picker label="From Date" v-model="fromdate" @input="changeFdate()">
                          </v-datetime-picker>
                          &nbsp;
                          <v-datetime-picker label="To Date" v-model="todate" ref="todate"
                            @input="changeTdate()"></v-datetime-picker>
                        </v-layout>
                      </v-form>
                      &nbsp;&nbsp;
                      <v-btn @click="play" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                        style=" font-size:10px;background: green; color: white; border-radius: 20px;">
                        {{ isplay ? "Replay" : "Play" }}
                      </v-btn>
                      <v-layout v-else justify-end>
                        <v-icon @click="play" size="30" color="#ffa726">{{ isplay ? "mdi-play-pause" :
      "mdi-play-circle-outline"
                          }}</v-icon>
                      </v-layout>
                      &nbsp;
                      <v-btn class="mr-0" @click="stop" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                        style="font-size:10px;background: #f44336; color: white; border-radius: 20px;">
                        Stop
                      </v-btn>
                      <v-icon @click="stop" size="30" left v-else color="#ffa726">mdi-stop-circle-outline</v-icon>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span style=" font-size:12px;color:black;" v-if="isplay">DistanceTravelled:</span>
                      <b style="font-size:12px;padding-right: 6%;color: #d84315">{{ distancetravel }}</b>
                      <v-btn color="#b71c1c" class="sm-0" @click="close14" style="border-radius: 20px; color: white;">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <div>
                      <FullLiveTrack ref="mytracking2" :vehdata.sync="fullclients" :data="ping"
                        @changetime="calctime" />
                    </div>
                    <!-- <div v-else>
                      <Routeplay :data="ping" :isPlay="isplay"  :starttime="fromdate"
                        :endtime="todate" @routeclosed="routeclosed"></Routeplay>
                    </div> -->
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        <!-- </v-layout> -->
      </div>
    </div>
    <div v-else>
      <div class="container-fluid" style="background-color: aliceblue;height: 100%;">
        <!-- Events Panel -->
    <transition name="slide-fade">
      <div v-if="selectedVehicleevents" class="events-panel2">
        <v-btn @click="closeEvents" class="primary close-btn" style="margin-top: 5%;margin-left: 2%">Close Events</v-btn>
        &nbsp;
        <v-btn @click="downloadEvents" class="warning close-btn"  style="margin-top: 5%;margin-right: 4%">Log Download</v-btn>
        <div class="events-content">
          <AlcoholRecordsChart :events="selectedVehicleevents" />
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <div v-if="vehicleSettings" class="events-panel2">
  <!-- <h1>Settings</h1> -->
  <v-btn @click="closeSettings" class="primary close-btn" style="margin-top: 5%;margin-left: 2%">Close</v-btn>
  <SettingsTrack ref="settingstracking" :data.sync="vehicleSettings" />
</div>

        </transition>
        <transition name="slide-fade">
      <div v-if="facerecognitionSettings" class="events-panel2">
  <!-- <h1>Settings</h1> -->
  <v-btn @click="closeFacerecognition" class="primary close-btn" style="margin-top: 5%;margin-left: 2%">Close</v-btn>
  <Facerecognition ref="settingstracking" :data.sync="facerecognitionSettings" />
</div>

        </transition>
    <!-- Display Map for Selected Vehicle -->
    <transition name="slide-fade">
          <div v-if="selectedVehiclemap" class="map-panel2">
            <div style="border-bottom: 2px dashed black;display:inline-flex;margin-top:-5%;">
                <v-form ref="datetimeform" v-model="valid3" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                  style="margin-top:-20px">
                  <v-row>
                    <v-datetime-picker label="From Date" ref="fromdate" v-model="fromdate"
                      @input="changeFdate()"></v-datetime-picker>
                    &nbsp;
                    <v-datetime-picker label="To Date" v-model="todate" ref="todate" :min="minToDate"
                      @input="changeTdate()"></v-datetime-picker>
                  </v-row>
                </v-form>
                <v-form v-else v-model="valid3" ref="datetimeform">
                  <v-layout style="margin-top: 20px">
                    <v-datetime-picker label="From Date" v-model="fromdate" @input="changeFdate()">
                    </v-datetime-picker>
                    &nbsp;
                    <v-datetime-picker label="To Date" v-model="todate" ref="todate"
                      @input="changeTdate()"></v-datetime-picker>
                  </v-layout>
                </v-form>
                <v-btn @click="play" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                  style="background: green; color: white; border-radius: 20px">
                  {{ isplay ? "Replay" : "Play" }}
                </v-btn>
                <v-layout v-else justify-end>
                  <v-icon @click="play" size="30" color="#ffa726">{{
      isplay ? "mdi-play-pause" : "mdi-play-circle-outline"
    }}</v-icon>
                </v-layout>
                &nbsp;
                <v-btn class="mr-0" @click="stop" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                  style="background: #ff3d41; color: white; border-radius: 20px">
                  Stop
                </v-btn>
                <v-icon @click="stop" size="30" left v-else color="#ffa726">mdi-stop-circle-outline</v-icon>
              </div>
            <v-btn @click="closeMap" class="close-btn" color="primary">Close Map</v-btn>
            <div v-if="!isplay" :id="`map-${selectedVehiclemap.gtime}`" class="map"></div>
            <Routeplay v-else :data="ping" :isPlay="isplay"  :starttime="fromdate"
                        :endtime="todate" @routeclosed="routeclosed"></Routeplay>
          </div>
        </transition>
        <v-layout>
          <v-dialog v-model="allopdialog" width="800px">
            <v-toolbar text color="#b2dfdb" class="" dark dense>
              <v-toolbar-title style="color:#00897b">All Operations</v-toolbar-title>
              <v-layout justify-end>
                <v-btn color="black" small @click="allopdialogclose" dark style="border-radius:20px;"><v-icon
                    color="white">
                    mdi-close
                  </v-icon></v-btn>
              </v-layout>
            </v-toolbar>
            <lastoperations ref="optracking" :data.sync="devdata" />
            <v-toolbar text color="#b2dfdb" class="" dark dense style="margin-top:10%;height: 30px;;"></v-toolbar>
          </v-dialog>
          <v-dialog v-model="eventmapdialog" width="600px">
            <v-toolbar text color="rgb(224, 105, 139)" dark>
              <v-toolbar-title style="color: white;">Location</v-toolbar-title>
              <v-layout justify-end>
                <v-btn color="black" small @click="eventTrackclose" dark style="border-radius:20px;"><v-icon
                    color="white">
                    mdi-close
                  </v-icon></v-btn>
              </v-layout>
            </v-toolbar>
            <EventTrack ref="eventtracking" :data.sync="eventdata" />
          </v-dialog>
          <v-dialog v-model="dialog14" width="500px">
            <v-card style="border-radius: 20px; background: aliceblue;">
              <v-toolbar color="#d1c4e9" size="70" style="border-radius: 20px; border-bottom: 1px solid black">
                <p style="font-size: 16px;font-weight:bold;text-align: center;margin-top: 20px;color: #5e35b1;">
                  Real Time Location
                </p>
                <v-icon class="ml-2" size="40" color="#673ab7">mdi-car-pickup</v-icon>
                <v-btn color="#e53935" class="sm-0" @click="close14"
                  style="border-radius: 20px; color: white;margin-left: 20%;">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-flex style="margin-top:2%"><span v-if="isplay" style="color:#6b861a;margin-left: 2%;">DistanceTravel :
                </span>
                <b style="color: #d84315">{{ distancetravel }}</b></v-flex>
              <div style="border-bottom: 2px dashed black;display:inline-flex;margin-top:-5%;">
                <v-form ref="datetimeform" v-model="valid3" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                  style="margin-top:-20px">
                  <v-row>
                    <v-datetime-picker label="From Date" ref="fromdate" v-model="fromdate"
                      @input="changeFdate()"></v-datetime-picker>
                    &nbsp;
                    <v-datetime-picker label="To Date" v-model="todate" ref="todate" :min="minToDate"
                      @input="changeTdate()"></v-datetime-picker>
                  </v-row>
                </v-form>
                <v-form v-else v-model="valid3" ref="datetimeform">
                  <v-layout style="margin-top: 20px">
                    <v-datetime-picker label="From Date" v-model="fromdate" @input="changeFdate()">
                    </v-datetime-picker>
                    &nbsp;
                    <v-datetime-picker label="To Date" v-model="todate" ref="todate"
                      @input="changeTdate()"></v-datetime-picker>
                  </v-layout>
                </v-form>
                <v-btn @click="play" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                  style="background: green; color: white; border-radius: 20px">
                  {{ isplay ? "Replay" : "Play" }}
                </v-btn>
                <v-layout v-else justify-end>
                  <v-icon @click="play" size="30" color="#ffa726">{{
      isplay ? "mdi-play-pause" : "mdi-play-circle-outline"
    }}</v-icon>
                </v-layout>
                &nbsp;
                <v-btn class="mr-0" @click="stop" v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                  style="background: #ff3d41; color: white; border-radius: 20px">
                  Stop
                </v-btn>
                <v-icon @click="stop" size="30" left v-else color="#ffa726">mdi-stop-circle-outline</v-icon>
              </div>
              <div>
                <v-col>
                  <v-row>
                    <v-flex><b style="color: #ec407a">Last Updated on:</b><span style="color: black">{{ lastupdate
                        }}</span></v-flex>
                  </v-row>
                </v-col>
                <div class="container-fluid" style="width:87%;margin-left: -2%;">
                  <div v-if="!isplay">
                    <Googlemaps v-if="!isplay" ref="liveTrack" :data="ping" @changetime="calctime"></Googlemaps>
                  </div>
                  <div v-else>
                    <!-- <div v-if="isLoading === true"
                      style="color: rgb(161, 231, 90);text-align: center;padding-left: 15%;padding-top: 50%;padding-bottom: 100%;font-weight: bold;font-size: xx-large;">
                      Loading Map....
                    </div>
                    <div v-else> -->
                    <Routeplay :data="ping" :isPlay="isplay" :starttime="fromdate" :endtime="todate"
                      @routeclosed="routeclosed"></Routeplay>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </v-card>
          </v-dialog>
          <v-dialog v-model="imgdialog" width="500px" @click:outside="imgclose">
            <v-toolbar text color="#f3e5f5" dark dense>
              <v-toolbar-title v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"> <span
                  style="font-size:15px;color:#8e24aa">TIME:{{ rtime }}</span>&nbsp;&nbsp;<span
                  style="font-size:15px;color:#8e24aa">BAC:
                  {{ bacvalue }}</span></v-toolbar-title>
              <v-toolbar-title v-else> <span style="font-size:11px;color:#8e24aa">TIME:{{ rtime }}</span>&nbsp;<span
                  style="font-size:11px;color:#8e24aa">BAC: {{ bacvalue }}</span></v-toolbar-title>
              <v-layout justify-end>
                <v-icon v-if="imageBytes != ''" @click="downloadImage(currentevent)" color="white" class="mr-4"
                  style="background:#8e24aa;padding:4px;border-radius:12px">mdi-download</v-icon>

                <v-icon v-if="imageBytes != ''" @click="fullscreen()" color="white" class="mr-4"
                  style="background:#8e24aa;padding:4px;border-radius:12px">mdi-fullscreen</v-icon>

                <v-btn color="#8e24aa" small @click="imgclose" dark style="border-radius:20px;"><v-icon color="white">
                    mdi-close
                  </v-icon></v-btn>
              </v-layout>
            </v-toolbar>
            <v-img id="my-video" :style="result == 'FAIL' ? 'border: 4px solid red;' : 'border: 3px solid green;'"
              v-bind:src="'data:image/jpeg;base64,' + imageBytes"
              v-if="imageBytes != '' && imageBytes != 'noimagefound'" />
            <v-card-text v-else class=text-center>
              <h2 style="background:#8e24aa;color:white;padding:5px;border-radius:20px;margin-bottom: 2%;">{{ imageloadingtext }}</h2>
            </v-card-text>
            <v-toolbar text color="#ba68c8" class="" dark dense style="margin-top:-2%">
              <v-toolbar-title>
                <span style="font-size:16px;margin-top: -2%;">RESULT: {{ result }}</span>
              </v-toolbar-title>
            </v-toolbar>
          </v-dialog>
          <v-dialog v-model="infodialog" max-width="500px">
            <v-card>
              <div class="grid1">
                <div style="color:#9e5384" class="grid-item1">
                  <b>Moving-</b>{{ moving }}
                </div>
                <div style="color:#d5b500" class="grid-item1">
                  <b>Offline</b>-{{ offline }}
                </div>
                <div style="color:#d50000" class="grid-item1">
                  <b>Online-</b>{{ online }}
                </div>
                <div style="color:#00d591" class="grid-item1">
                  <b>Pass-</b>{{ passes }}
                </div>
                <div style="color:#ab68c8" class="grid-item1">
                  <b>Fails-</b>{{ fails }}
                </div>
                <div style="color:#c3d500" class="grid-item1">
                  <b>ByPass-</b>{{ bypass }}
                </div>
              </div>
            </v-card>
          </v-dialog>
          <v-dialog v-model="prfdialog" max-width="500px">
            <v-card style="border-radius:20px;overflow: hidden;">
              <v-toolbar color="#81c784" dark dense style="border-radius:20px">
                <v-toolbar-title>Profile</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="prfdialog = false" style="background:black;border-radius:20px">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-col>
                <v-row>
                  <v-col>
                    <div class="d-flex justify-center">
                      <v-img src="https://www.alcobrake.com/assets/images/old_logo.png" max-width="200"></v-img>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="justify-center" v-if="profiledata != null">
                  <v-col class="col-md-10">
                    <v-card style="border-radius:20px;background:#cccccc;overflow: hidden;">
                      <v-card-text class="p-right"><b class="p-left"><v-icon class="off-icon">mdi-account</v-icon>Full
                          Name
                          :</b> {{ profiledata.dealerName }}</v-card-text>
                      <v-card-text class="p-right"><b class="p-left"><v-icon
                            class="off-icon">mdi-email-outline</v-icon>E-mail :</b>{{ profiledata.dealerEmail
                        }}</v-card-text>
                      <v-card-text class="p-right"><b class="p-left"><v-icon
                            class="off-icon">mdi-cellphone-iphone</v-icon>Mobile Number :</b>
                        {{ profiledata.dealerMobile1 }}</v-card-text>
                      <v-card-text class="p-right"><b class="p-left"><v-icon
                            class="off-icon">mdi-account-multiple</v-icon>Access type :</b>
                        {{ profiledata.accesslevel }}</v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <span v-else>Profile Data Not Fount</span>
              </v-col>
            </v-card>
          </v-dialog>
              
          <v-card style="width: 95%;margin-left: 2.5%;background-color: aliceblue;margin-top: 2%;">
            <v-toolbar>
              <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
              <h5 style="margin-left: 2%;">Dashboard</h5>
              <div style="width: 100%;margin-left: 10%;display: inline-flex;margin-top: 5%;">
                <v-text-field v-model="search" label="Search" append-icon="mdi-magnify"></v-text-field>
                <v-icon dark size="25px" style="color: #9e9e9e;margin-top: -10%;margin-left:2%" @click="clearSearch">
                  mdi-refresh
                </v-icon>
              </div>
              
            </v-toolbar>
            <div style="margin-top:4%;width: 100%;overflow-x: auto;display: inline-flex;margin-left: -3%;">
              <v-navigation-drawer v-model="drawer" absolute left>
                <v-list nav dense>
                  <v-list v-model="group" active-class="deep-purple--text text--accent-4">
                    <v-list-item>
                      <v-list-item-title @click="idialog">Status</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="filteredData('fail')">Drunk</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="filteredData('bypass')">Bypass</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="filteredData('overspeed')">Overspeed</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="isFiltered = false">Clearfilters</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="profiledealer()">Profile</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title @click="onLogout">Logout</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-title @click="servicesPage">Services</v-list-item-title>
                          </v-list-item> -->
                       <!-- Expandable Services Section -->
        <v-list-group v-model="servicesExpanded" no-action>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-title>Services</v-list-item-title>
            </v-list-item>
          </template>

          <!-- Services Sub-Items -->
           <!-- Services Sub-Items -->
<!-- Services Sub-Items -->
<v-list-item @click="servicesDialog = true; updateActiveComponent('ServiceCustomerInfo')">
    <v-list-item-title>
      <v-icon left small>mdi-account</v-icon> Customer Information
    </v-list-item-title>
  </v-list-item>
  <v-list-item @click="servicesDialog = true; updateActiveComponent('ServiceInvoices')">
    <v-list-item-title>
      <v-icon left small>mdi-file-document</v-icon> Invoice
    </v-list-item-title>
  </v-list-item>
  <v-list-item @click="servicesDialog = true; updateActiveComponent('ServiceTransactions')">
    <v-list-item-title>
      <v-icon left small>mdi-swap-horizontal</v-icon> Transaction
    </v-list-item-title>
  </v-list-item>
  <v-list-item @click="servicesDialog = true; updateActiveComponent('ServiceAppointments')">
    <v-list-item-title>
      <v-icon left small>mdi-calendar-check</v-icon> Appointments
    </v-list-item-title>
  </v-list-item>
</v-list-group>
                  </v-list>
                </v-list>
              </v-navigation-drawer>
              <!-- Dialog for Mobile View -->
  <!-- Mobile View -->
  <v-dialog v-model="servicesDialog" fullscreen hide-overlay transition="dialog-bottom-transition" no-click-animation>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click.stop="servicesDialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
    </v-toolbar>

    <!-- Render the active tab component -->
    <component :is="activeComponent" />
  </v-card>
</v-dialog>
              <div id="scrollable-div">
                <div class="box"><span
                    style="font-size:13px;color: #546e7a;margin-left: 10%;margin-top: -5%;">All</span>
                  <p style="font-size:13px;font-weight: bold;color: #546e7a;margin-left: 10%;">{{ all }}</p>
                  <p style="width: 80%;height: 5px;margin-left:10%;background-color: #64b5f6;margin-top: -10%;"></p>
                </div>
                <div class="box"><span
                    style="font-size:13px;color: #546e7a;margin-left: 2%;margin-top: -5%;display:inline-flex;">Overspeed</span>
                  <p style="font-size:13px;font-weight: bold;color: #546e7a;margin-left: 10%;">{{ overspeed }}</p>
                  <p style="width: 80%;height: 5px;margin-left:10%;background-color: #f57c00;margin-top: -10%;"></p>
                </div>
                <div class="box"><span
                    style="font-size:13px;color: #546e7a;margin-top: -5%;margin-left: 10%;">Running</span>
                  <p style="font-size:13px;font-weight: bold;color: #546e7a;margin-left: 10%;">{{ moving }}</p>
                  <p style="width: 80%;height: 5px;margin-left:10%;background-color: #00c853;margin-top: -10%;"></p>
                </div>
                <div class="box"><span
                    style="font-size:13px;color: #546e7a;margin-top: -5%;margin-left: 10%;">Idle</span>
                  <p style="font-size:13px;font-weight: bold;color: #546e7a;margin-left: 10%;">{{ Idle }}</p>
                  <p style="width: 80%;height: 5px;margin-left:10%;background-color: #ffd600;margin-top: -10%;"></p>
                </div>
                <div class="box">
                  <span style="font-size:13px;color: #546e7a;margin-top: -5%;margin-left: 10%;">Stop</span>
                  <p style="font-size:13px;font-weight: bold;color: #546e7a;margin-left: 10%;">{{ offline }}</p>
                  <p style="width: 80%;height: 5px;margin-left:10%;background-color: #d50000;margin-top: -10%;"></p>
                </div>
              </div>
            </div>
            <v-row>
              <v-col class="col-sm-4">
                <div class="container-fluid">
                  <v-list class="max-v-list-height2">
                    <v-list-item v-for="(item, i) in resultQuery" :key="i">
                      <v-card style="padding: 2%;width: 100%;margin: 1%;">
                        <div>
                          <v-row>
                            <v-col class="col-4">
                              <v-img v-if="item.Engine.enginerunstate == 1 && parseFloat(item.Gps.speed) > 0"
                                src='../assets/bus.png' style="width: 60%;" @click="openMap(item)" />
                              <v-img v-else-if="item.Engine.enginerunstate == 1 && parseFloat(item.Gps.speed) == 0.0"
                                src='../assets/bus-idle.png' style="cursor: pointer;width: 60%;"
                                @click="openMap(item)" />
                              <v-img v-else src='../assets/bus-off.png' style="cursor: pointer;width: 60%;"
                                @click="openMap(item)" />
                            </v-col>
                            <v-col>
                              <span style="font-size:13px;font-weight: bold;color: black;">
                                {{ item.Vehicle.regnum }}</span>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col>
                              <v-icon style="color: rgb(18, 70, 73);margin-left: 30%;" @click="openMap(item)">
                                mdi-crosshairs-gps</v-icon>
                            </v-col>
                          </v-row>
                        </div>
                        <v-divider></v-divider>
                        <div>
                          <v-row style="margin-top:2%;">
                            <v-col style="margin-left:5%;">
                              <v-icon
                                style="border: 0.5px solid #4db6ac;color: #4db6ac;border-radius: 30%;">mdi-account</v-icon>
                            </v-col>
                            <v-col style="margin-left: -20%;">
                              <v-row>
                                <p style="font-size:13px;font-weight: bold;color: #4db6ac;"></p>
                              </v-row>
                              <v-row>
                                <p
                                  style="font-size:13px;color: #4db6ac;margin-top: -15%;word-break: break-all;width:40%">
                                  {{ item.Vehicle.client }} </p>
                              </v-row>
                            </v-col>
                            <v-col style="margin-left:-15%;">
                              <v-icon
                                :style="item.Engine.enginerunstate == 0 ? { 'color': 'red' } : { 'color': 'green' }"
                                style="border: 0.5px solid #ab4573;border-radius: 30%;">mdi-engine</v-icon>
                            </v-col>
                            <v-col style="margin-left:-20%;">
                              <v-row>
                                <p style="font-size:13px;font-weight: bold;color: #ab4573;">ENGINE</p>
                              </v-row>
                              <v-row>
                                <p style="font-size:13px;color: black;margin-top: -15%;">{{ item.Engine.enginerunstate
      ==
      "0" ? "OFF" : "ON" }}</p>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col style="margin-left:5%;">
                              <v-icon
                                style="border: 0.5px solid rgb(231, 95, 95);color:rgb(231, 95, 95);border-radius: 30%;">mdi-speedometer</v-icon>
                            </v-col>
                            <v-col style="margin-left: -20%;">
                              <v-row>
                                <p style="font-size:13px;font-weight: bold;color: rgb(231, 95, 95);">SPEED</p>
                              </v-row>
                              <v-row>
                                <p style="font-size:13px;color: black;margin-top: -15%;">{{ item.Gps.speed.slice(0,
      4) }}km/h</p>
                              </v-row>
                            </v-col>
                            <v-col style="margin-left:-15%;">
                              <v-icon
                                style="border: 0.1px solid #ecc956;color: #ecc956;border-radius: 30%;">mdi-av-timer</v-icon>
                            </v-col>
                            <v-col style="margin-left:-20%;">
                              <v-row style="margin-right:10%">
                                <p style="font-size:13px;font-weight: bold;color: #ecc956;">SINCE</p>
                              </v-row>
                              <v-row>
                                <p style="font-size:13px;color: black;margin-top: -15%;">{{ calctime(item.Gps) != "NA" ? calctime(item.Gps) : '' }}</p>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col style="margin-bottom: 1%;">
                              <Barchart class="pointer-image" ref="alcoholdata" :devid="item"></Barchart>
                            </v-col>
                          </v-row>
                        </div>
                        <v-divider></v-divider>
                        <div>
                          <v-row style="margin-top: 2%;">
                            <v-col>
                              <v-row style="margin-left: 2%;">
                                <span style="font-size: x-small;font-weight: bold;">Last Action</span>
                              </v-row>
                              <v-row style="margin-left: 2%;">
                                <span style="font-size:12px;color:black" v-on:mouseover="handleMouseOver(item)"
                                  v-on:mouseout="handleMouseclose">{{ getEventtype(item) }}</span>
                                <p style="text-align: center;width: 60%;height: 20%;border-radius:10px;color: white;font-size:x-small;font-weight: bold;margin-bottom:-2px;background-color: dodgerblue;"
                                  v-if="isMouseOver">{{ item.Event.rtime }}</p>
                                <p style="font-size:small;color:dodgerblue;margin-left:5%;margin-top: -2%;text-decoration: underline;"
                                  @click="allopTrack(item)">View</p>
                              </v-row>
                              <v-row>
                              <v-col cols="3" style="margin-top: -5%;margin-left:2%;">
                                <v-icon style="color: #558b2f;" size="30"
                                  @click="onLoadevents(item)">mdi-calendar-month</v-icon>
                              </v-col>
                               <v-col cols="3" style="margin-top: -5%">
                              <v-icon size="22" @click="openSettings(item)" style="color: #4774e6;"
                                >mdi-cog</v-icon>
                            </v-col>
                            <v-col  cols="5" style="margin-top: -5%">
                              <v-icon size="22" @click="openFacerecognition(item)" style="color: red;"
                                >mdi-face-recognition</v-icon>
                            </v-col>
                          </v-row>
                            </v-col>
                            <v-col>
                              <v-row style="margin-top: -7%;">
                                <SpeedChart ref="speeddata" :devid="item"></SpeedChart>
                              </v-row>
                              <v-row style="margin-top: 10%;">
                                <v-img max-height="40" max-width="20" style="margin-left: 10%;"
                                  @click="goCommand(item, '84')" src="../assets/Dreger413__1.png"></v-img>
                                <v-icon style="color: #558b2f;margin-left: 10%;"
                                  @click="goCommand(item, '83')">mdi-camera-enhance</v-icon>
                                <v-icon style="color: #558b2f;margin-left: 10%;"
                                  @click="goCommand(item, '88')">mdi-cursor-pointer</v-icon>
                                  <!-- <v-col style="margin-left: 10%;">  -->
  <v-icon style="color: #558b2f;margin-left: 10%;" @click="openEditDialog(item)">mdi-pencil</v-icon>
<!-- </v-col> -->
                              </v-row>
                            </v-col>
                          </v-row>
                           <v-row style="margin-top:-30px">
                            <v-col cols="1">
                            <div class="text-center">
    <div class="text-center">
    <v-rating
      v-model="vehicleRatings[item.Vehicle.cbid]" 
      active-color="blue"
      color="orange-lighten-1"
      half-increments
      length="5"
      readonly
    ></v-rating>
  </div>

  </div>
</v-col>
                          </v-row>
                          <v-row>
                            <v-col style="margin-top:-4%;margin-bottom:-6%;">
                              <p style="font-size:15px;margin-left: 2%;">Last Update
                                :
                                {{ calctime(item.Gps) != 'NA' ? calctime(item.Gps) : '' }}</p>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-layout>
      </div>
    </div>
    </v-app>
    <v-app v-else>
      <v-dialog v-model="dialog15" max-width="800px">
        <v-card class="elevation-12">
          <v-col cols="12">
            <v-row class="justify-center">
              <v-col class="col-md-8" align="center">
                <div style="
                  background: #334c3e !important;
                  color: white;
                  border-radius: 20px;
                  padding: 10px;
                ">
                  <v-icon color="green" class="mr-4">mdi-cash-multiple</v-icon> !Warning
                </div>
              </v-col>
            </v-row>
          </v-col>
          <div class="container">
<h1>Payment Gateway Not Initialized Yet, Please contact Admin</h1>
          </div>
          </v-card>
          </v-dialog>
      <v-dialog v-model="dialog2" max-width="800px">
        <v-card class="elevation-12">
          <v-toolbar text color="teal" class="" dark dense>
            <v-row>
              <v-toolbar-title style="margin-left: 10px; color: black"><v-icon color="white"
                  class="mr-4">mdi-cash-multiple</v-icon>You
                Paying Cash </v-toolbar-title>
              <v-layout justify-end>
                <v-btn color="primary" @click="clearclose" dark
                  v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">Close</v-btn>
                <v-icon v-else color="primary" size="40px" @click="clearclose">
                  mdi-close
                </v-icon>
              </v-layout>
            </v-row>
          </v-toolbar>
          <v-card-text style="margin-top:20px">
            <v-form>
              <v-text-field v-model="dealerpassword" prepend-icon="mdi-lock" name="dealerpassword" label="AdminPassword"
                counter @click:append="show2 = !show2"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-end>
              <v-btn color="#666600" @click="confirmPaycash" style="color: white">CASH PAID</v-btn>
            </v-layout>
          </v-card-actions>
        </v-card>
      
      </v-dialog>
      <div>
        <v-toolbar>
                
                <v-row>
                  <v-img src='../../public/old_logo.png' max-width="240" style="margin-top:1;margin-left:20px;" />
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="onLogout">Logout</v-btn>
                  </v-row>
                </v-toolbar>
        <h2>{{ this.header }}</h2>
        <p>{{ this.message }}. Please contact customer care for assistance.</p>
        <v-btn class="primary" @click="payNow" v-if="header == 'Payment Pending'"><v-icon color="white"
            class="mr-4">mdi-web</v-icon>PAY Online</v-btn>
        &nbsp;
        <v-btn class="success" @click="payCash" v-if="header == 'Payment Pending'"><v-icon color="white"
            class="mr-4">mdi-cash-multiple</v-icon>PAY Cash</v-btn>
        <v-btn class="primary" v-else>Send Request</v-btn>
        <div>
          <p>Contact Customer Care:</p>
          <ul>
            <li>Email: info@alcobrake.com</li>
            <li>Phone: +1 844-425-2627</li>
          </ul>
        </div>
      </div>
    </v-app>
  </v-app>
  <v-app v-else> 
    <div>
      <v-toolbar>
                
                <v-row>
                  <v-img src='../../public/old_logo.png' max-width="240" style="margin-top:1;margin-left:20px;" />
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="onLogout">Logout</v-btn>
                  </v-row>
                </v-toolbar>
      <h3 v-if="!loading">You are Not Subscribed to Alcobrake, Please Visit <span>
    <a href="https://www.alcobrake.com" target="_blank" rel="noopener noreferrer">
      www.alcobrake.com
    </a>
  </span> to Subscribe.</h3>
  <!-- <h3 v-else> Loading....</h3> -->
   <div v-else>
  <Preloader />
</div>
    </div>
  </v-app>
</template>

<script>
import mapboxgl from "mapbox-gl";
import EventTrack from "./EventTrack";
import SettingsTrack from "./settings.vue";
import Facerecognition from "./facerecognition.vue";
import FullLiveTrack from "./FullLiveTrack";
import Googlemaps from "./fleetmaps.vue";
import Routeplay from "./routeplay.vue";
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Barchart from "./barchart";
import SpeedChart from './fleetspeedchart.vue';
import lastoperations from "./lastoperations.vue";
import Eventemit from "../components/eventemit";
import AlcoholRecordsChart from './AlcoholRecordsChart.vue';
import DatetimePicker from "vuetify-datetime-picker";
import axios from "axios";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { nextTick } from 'vue';
import 'moment-timezone';
import Preloader from './preloader.vue';
import { flatMap } from "lodash";
import ServiceCustomerInfo from './serviceCustomerInfo';
  import ServiceInvoices from './serviceInvoices';
  import ServiceTransactions from './serviceTransactions';
  import ServiceAppointments from './serviceAppointments';

Vue.use(axios);
Vue.use(DatetimePicker);

export default {
  name: "Home",
  data() {
    return {
      editDialog: false,
      editedItem: {},
      servicesExpanded: false, // Controls the expansion of the services section
      activeComponent: null, // Holds the currently active component
      tab: 'customer-info', // Default selected tab
      currentComponent: 'ServiceCustomerInfo',
      servicesDialog: false,
      activeTab: 0,
      dialogTitle: '', // Title of the dialog
      dialog:false,
      phoneRules: [
      v => !!v || 'Phone is required',
      v => /^(\+)?\d{10,13}$/.test(v) || 'Phone must be between 10 and 13 digits, optionally starting with +'

    ],
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'Email must be valid',
    ],
      components: {
     ServiceCustomerInfo,
        ServiceInvoices,
        ServiceTransactions,
        ServiceAppointments
  },
      fromtodatedialog:false,
      fromdatedialog:false,
      date:true,
      time:false,
      fromonlydate: "",
      fromonlytime: "",
      fromtoonlydate: "",
      fromtoonlytime: "",
      payingamount:0.00,
      dealerpassword:null,
      dialog2:false,
      dialog15:false,
      grantAccess:false,
      warningmessage:"",
      header:'',
      message:'',
      subsription:null,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      selectedVehicleevents:null,
      selectedVehiclemap:null,
      vehicleSettings:null,
      facerecognitionSettings:null,
      dialog10:false,
      grandTotal: 0,
      showServices: false, // State to control visibility of services content
        isSidenavCollapsed: false,
        vehicleRatings: {},
      // pk: "pk_test_51Kl5mQD2VBUtaWz7ViJJnZpjStbKM6nD40JBGUsuSYtfDEd1c9ne0BZ7QMs33Pg2O3cjEnVfUzlX5YFGKYRttgfM00KCuHxxvp",
      elementsOptions2: {
        appearance: {},
        clientsecret:
          "pi_3LCLgTD2VBUtaWz70jK1Kd1v_secret_djSefkeS6okGLEu5NV9wpo7Pt",
      },
      dealeritems: [],
      dealerData: { "dealerId": "", "dealerName": "", "dealerEmail": "", "dealerMobile1": "", "dealerMobile2": "", "dealerPassword": "", "dealerAddress": "", "accesslevel": "", "state": "", "city": "", "zipcode": "" },
      enableClickEvent: true,
      datetime: {
        type: [Date, String],
        default: null,
      },
      decimalPlaces: 0,
      distancetravel: null,
      all: 0,
      overspeed: 0,
      Idle: 0,
      ping: [],
      toolbar: false,
      lastupdate: null,
      mobileview: false,
      isplay: false,
      fromdate: null,
      todate: null,
      valid: true,
      valid3: true,
      group: false,
      drawer: false,
      isMouseOver: false,
      devdata: null,
      eventdata: null,
      eventmapdialog: false,
      allopdialog: false,
      fleetuser: JSON.parse(localStorage.getItem("fleetuser")),
      loading: false,
      search: '',
      pusher: null,
      channel: null,
      eventssearch: '',
      currentStop: null,
      imageBytes: null,
      dialog14: false,
      prfdialog: false,
      imgdialog: false,
      infodialog: false,
      devices: [],
      selectedItem: null,
      isFiltered: false,
      mydata: null,
      speed: 0,
      fullclients: [],
      moving: 0,
      offline: 0,
      online: 0,
      profiledata: null,
      customStyle: {
        size: 50,
        mainBackgroundColor: 'white',
        scaleValuesColor: 'dodgerblue',
        scaleValuesFontFamily: "'Titillium Web', sans-serif"
      },
      dealershipinvoices:[],
      fails: 0,
      passes: 0,
      bypass: 0,
      logstext: 'NO DATA FOUND',
      singleVehicle:{},
      bacvalue: '',
      devid: '',
      rtime: '',
      result: '',
      imageloadingtext: 'IMAGE NOT FOUND',
      passtestevents: [],
      failtestevents: [],
      engonevents: [],
      engoffevents: [],
      items: [],
      isDownloading: false,
    };
  },
  watch: {
    // activeTab: {
    //   immediate: true,
    //   handler(newValue) {
    //     console.log('Tab changed to:', newValue);
    //   }
    // },
    activeComponent() {
    this.updateActiveTab();
  },
  //   servicesDialog(val) {
  //     console.log('Services Page')
  //     console.log(val);
  //   if (!val) {
  //     this.activeTab = 0;
  //     this.currentComponent = 'ServiceCustomerInfo';
  //   }
  // },
    activeTab() {
    switch (this.activeTab) {
      case 0:
        this.currentComponent = 'ServiceCustomerInfo'
        break
      case 1:
        this.currentComponent = 'ServiceInvoices'
        break
      case 2:
        this.currentComponent = 'ServiceTransactions'
        break
      case 3:
        this.currentComponent = 'ServiceAppointments'
        break
    }
  },
    fromdate(val) {
      this.fromdate = val
    },
    todate(val) {
      this.todate = val
    },
    isplay(val) {
      this.isplay = val;
    },
    profiledata(val) {
      this.profiledata = val;
    },
    fullclients(val) {
      this.fullclients = val;
    },
    items(val) {
      this.items = val;
    },
    // isSingle(val) {
    //   this.isSingle = val;
    // },
    fails(val) {
      this.fails = val
    },
    passes(val) {
      this.passes = val
    },
    bypass(val) {
      this.bypass = val
    },
    moving(val) {
      this.moving = val
    },
    offline(val) {
      this.offline = val
    },
    online(val) {
      this.online = val
    },
    speed(val) {
      this.speed = val
    },
    mydata(val) {
      this.mydata = val;
    },
  },
  methods: {
    selectTab(tab) {
      this.tab = tab; // Update the selected tab
    },

openEditDialog(item) {
  // console.log("Edit icon clicked", item);
  
  this.editDialog = false; // Reset first
  this.$nextTick(() => {
    this.editDialog = true; // Ensure it reopens

    this.editedItem = { ...item.Vehicle };
  });
},
    closeDialog() {
      this.editDialog = false;
      this.editedItem = {}; // Reset edited item
    },
    async saveEdit() {
  if (!this.editedItem.id) {
    alert("Error: No ID found!");
    return;
  }

  const apiUrl = `https://robopower.xyz/us/fullclients/editindiaclient/${this.editedItem.id}`;

  try {
    let ffData = new FormData();

    // ✅ Append fields one by one
    ffData.append("client", this.editedItem.client);
    ffData.append("clientphone1", this.editedItem.clientphone1);
    ffData.append("clientphone2", this.editedItem.clientphone2);
    ffData.append("email", this.editedItem.email);
    ffData.append("dob", this.editedItem.dob);
    ffData.append("clientAddress", this.editedItem.clientAddress);
    ffData.append("city", this.editedItem.city);
    ffData.append("state", this.editedItem.state);
    ffData.append("zipcode", this.editedItem.zipcode);
    ffData.append("regnum", this.editedItem.regnum);

    //  Check data before sending
    for (let pair of ffData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    //  Send data
    const response = await axios.post(apiUrl, ffData, {
      headers: { "Content-Type": "multipart/form-data" }
    });

    console.log("Response:", response.data);
    alert("Data saved successfully!");
    this.editDialog = false; 

  } catch (error) {
    console.error("Error saving data:", error);
    alert("Failed to save data!");
  }
},
updateActiveComponent(componentName) {
      this.activeComponent = this.components[componentName];
    },
    servicesPage() {
      this.servicesDialog = !this.servicesDialog;
      if (this.servicesDialog) {
        if (this.activeComponent === null) {
          this.activeComponent = this.components['ServiceCustomerInfo'];
        }
      }
    },
    updateActiveTab() {
    if (this.activeComponent === this.components['ServiceCustomerInfo']) {
      this.activeTab = 0;
    } else if (this.activeComponent === this.components['ServiceInvoices']) {
      this.activeTab = 1;
    } else if (this.activeComponent === this.components['ServiceTransactions']) {
      this.activeTab = 2;
    } else if (this.activeComponent === this.components['ServiceAppointments']) {
      this.activeTab = 3;
    }
  },


    
    ondialog(){
this.fromdatedialog = true
    },
    onClose(){
this.fromdatedialog = false 
this.fromonlydate = "";
this.fromonlytime = "";
this.fromdate = ""
    }, 
SelectedfromDateTime(){
      this.fromdate = this.fromonlydate + " " + this.fromonlytime
      this.fromdatedialog = false 
    },
    ontodialog(){
this.fromtodatedialog = true
    },
    ontoClose(){
this.fromtodatedialog = false 
this.fromtoonlydate = "";
this.fromtoonlytime = "";
this.todate = ""
    },
    onClear(){
      this.fromdatedialog = false 
this.fromonlydate = "";
this.fromonlytime = "";
this.fromdate = ""
this.fromtodatedialog = false 
this.fromtoonlydate = "";
this.fromtoonlytime = "";
this.todate = ""
    },
    SelectedtoDateTime(){
      this.todate = this.fromtoonlydate + " " + this.fromtoonlytime
      this.fromtodatedialog = false 
    },
    confirmPaycash(){
      if (this.dealerpassword) {
        var usermail = "admin@alcobrake.com";
        var userpassword = this.dealerpassword;
        Vue.axios
          .get(
            "https://robopower.xyz/us/calogin/getuniquelogin?emailId=" +
              usermail +
              "&password=" +
              userpassword
          )
          .then((response) => {
              if(response.data){
              var logindata = response.data[0];
              if (
                usermail == logindata.emailId &&
                this.dealerpassword == logindata.password
              ) {

            Vue.axios.post('https://robopower.xyz/us/cadealerpayments/clearpayments/' + this.fleetuser.dealerId)
        .then((res) => {
            // console.log(res.data)
          if (res.data == "Dealer Payment cleared") {
            var fData = new FormData();
            fData.append('to', this.fleetuser.emailId)
            fData.append('message', "Dear dealer, we are pleased to inform you that your outstanding payment has been successfully processed and cleared in cash mode of invoice " +this.payind+" -Alcobrake.")
            fData.append('subject', "SUCCESS TRANSACTION")
            Vue.axios.post('https://robopower.xyz/v2/email/sendemail', fData)
              .then((res) => {
                // console.log(res.status)
                if (res.status == 200) {
                  setTimeout(() => {
                    this.$store
              .dispatch("alert/error", "Cash Collected")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
              this.dealerpassword =null;
              this.dialog2 = false;
              window.location.reload();
                  }, 2000)
                }
              }).catch((err) => {
                alert(err)
              })

          }
        }).catch((err) => {
          alert(err)
        })

            
              } else{
                this.$store
              .dispatch("alert/error", "Entered Incorrect Password")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
              }
            } else{
              this.$store
              .dispatch("alert/error", "Entered Incorrect Password")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            }
          })
          .catch((err) => {
            this.$store.dispatch("alert/error", err).then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          });
      }else{
                this.$store
              .dispatch("alert/error", "Please Enter Password")
              .then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
              }
      
    },
    payNow(){
      this.dialog15 = true;
      // console.log("dokdfnjkd")
      // this.createIndent();
    },
    clearclose(){
      this.dialog2 = false;
      this.dealerpassword =null;
    },
    payCash(){
      this.dialog2 = true;
    },
    issmsvaluechange(item) {
      var issmsvalue = Number(item.Vehicle.issms);
      // console.log(issmsvalue)
      const formData2 = new FormData();
      formData2.append("issms", issmsvalue.toString());
      // console.log(issmsvalue)
      Vue.axios
        .post(
          "https://robopower.xyz/us/fullclients/editsmsservices/" + item.Vehicle.id,
          formData2
        )
        .then((res) => {
          if (res.status == 200) {
            // this.reLoad();
            if (issmsvalue == 1) {
              this.$store.dispatch("alert/success", "SMS ALERT ON").then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear");
                }, 2000);
              });
            } else {
              this.$store
                .dispatch("alert/success", "SMS ALERT OFF")
                .then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
            }
          } else {
            this.$store.dispatch("alert/error", "error").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear");
              }, 2000);
            });
          }
        });
    },
    close10(){
this.dialog10 = false;
    },
    textdownload() {
      this.type = "text";
      this.downloadItem(this.expanded[0], 0);
      this.postdata = null;
      this.dialog = false;
    },
    csvdownload() {
      this.type = "csv";
      this.downloadItem(this.expanded[0], 0);
      this.postdata = null;
      this.dialog = false;
    },
    // async requestNotificationPermission() {
    //         const permission = await Notification.requestPermission();
    //         if (permission === 'granted') {
    //           console.log("YES GRANTED")
    //             this.subscribeUserToPush();
    //         } else {
    //             console.error('Permission for notifications was denied.');
    //         }
    //     },
    async requestNotificationPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
        console.log("YES GRANTED");
        this.subscribeUserToPush();
    } else if (permission === 'denied') {
        console.error('Permission for notifications was denied.');
        alert('To enable notifications, please go to your browser settings and allow notifications for this site.');
    } else {
        console.warn('Notification permission is default. No action taken.');
    }
},


async subscribeUserToPush() {
    try {
        const backendResponse = await fetch('https://robopower.xyz/webpush/wp/config');
        const { publicVapidKey, privateVapidKey } = await backendResponse.json();

        console.log(publicVapidKey);

        if (!publicVapidKey) {
            console.error('Failed to fetch VAPID key');
            return;
        }

        const applicationServerKey = this.urlBase64ToUint8Array(publicVapidKey);
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        const existingSubscription = await registration.pushManager.getSubscription();

        if (existingSubscription) {
            console.log('Existing subscription found:', existingSubscription);
            const existingKey = existingSubscription.options.applicationServerKey;
            if (existingKey !== applicationServerKey) {
                console.log('Subscription has a different applicationServerKey. Unsubscribing...');
                await existingSubscription.unsubscribe();
                console.log('Re-subscribing with new key...');
            } else {
                console.log('Subscription already exists with the same key');
                return;
            }
        }

        const pushSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey, 
        });

        console.log('New subscription:', pushSubscription);
        const p256dhBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(pushSubscription.getKey('p256dh'))));
        const authBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(pushSubscription.getKey('auth'))));

        console.log('p256dhBase64:', p256dhBase64);
        console.log('authBase64:', authBase64);
        const response = await fetch('https://robopower.xyz/webpush/wp/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              publicKey:publicVapidKey,
              privateKey:privateVapidKey,
                endpoint: pushSubscription.endpoint,
                keys: {
                    p256dh: p256dhBase64,  // base64 encoded p256dh
                    auth: authBase64,       // base64 encoded auth
                },
                dealerid: parseInt(this.fleetuser.dealerId),
                source: "fleet",
                createddate: moment(moment().format("MM/DD/YYYY HH:mm:ss")),
            }),
        });

        if (response.ok) {
            console.log('Subscription saved successfully!');
        } else {
            console.error('Failed to save subscription');
        }

    } catch (error) {
        console.error('Error during subscription process:', error);
    }
},

// Helper function to convert the VAPID key to a Uint8Array
urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64);
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
},

    updateScreenSize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
    closeMap(){
      this.selectedVehiclemap = null;
      this.ping =[]
      this.$refs.datetimeform.reset();
      this.onClear()
    },
   downloadEvents(){
    this.isDownloading = true;
      this.$refs.chartContent.generatewithtintablePDF(() => {
      this.isDownloading = false;
  });
    },
     
    closeEvents(){
this.selectedVehicleevents = null;
this.vehicleSettings = null;
    },
    closeSettings(){
this.vehicleSettings = null;
this.selectedVehicleevents = null;
    },
    closeFacerecognition(){
this.facerecognitionSettings = null;
this.selectedVehicleevents = null;
    },
    getReports(){
      this.$router.push("Fleetreports")
    },

    profiledealer() {
      this.prfdialog = true;
    },
    handleClick() {
      console.log('List clicked');
    },
    geofence() {
      this.$router.push('/fleet/geofence');
    },
    handledistance(data) {
      this.distancetravel = this.roundNumber(data.distance, this.decimalPlaces);
      this.isloading = data.loading
    },
    roundNumber(number, decimalPlaces) {
      const factor = 10 ** decimalPlaces;
      return Math.round(number * factor) / factor + "KM";
    },
    routeclosed() {
      console.log("poo")
    },
    onRefresh() {
      if (this.isplay == false) {
        if (this.$refs.liveTrack) {
          this.$refs.liveTrack.onLoad();
        }
      }
    },
    changeFdate() {
      this.isplay = false;
      this.fromdate = moment(this.fromdate, "YYYY-MM-DD").format("YYYY-MM-DD HH:mm:ss");
      if (this.todate != null) {
        this.checkTime();
      }
      return;
    },
    changeTdate() {
      this.isplay = false;
      this.todate = moment(this.todate, "YYYY-MM-DD").format(
        "YYYY-MM-DD HH:mm:ss"
      );
      if (this.fromdate != null) {
        this.checkTime();
      }

    },
    checkTime() {
      const fromDateTime = moment(this.fromdate, "YYYY-MM-DD HH:mm:ss");
      const toDateTime = moment(this.todate, "YYYY-MM-DD HH:mm:ss");

      const duration = moment.duration(toDateTime.diff(fromDateTime));
      const hours = duration.asHours();

      if (hours > 24) {
        this.fromdate = null;
        this.todate = null;
        this.$refs.datetimeform.reset();
        this.$store
          .dispatch("alert/error", "The chosen date and time range must not exceed a 24-hour period")
          .then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear");
            }, 2000);
          });
      }
    },
    play() {
      // var fromdate = moment(this.fromdate).format("MM/DD/YYYY HH:mm:ss");
      // var todate = moment(this.todate).format("MM/DD/YYYY HH:mm:ss");

            if (this.isplay == true) {
              this.isplay = false;
              if (this.fromdate && this.todate) {
                setTimeout(() => {
                  this.isplay = true;
                }, 200);
              } else {
                this.$store.dispatch("alert/error", "please select data and time").then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              }
            } else {
              if (this.fromdate && this.todate) {
                // console.log("isplay4")
                this.isplay = true;
              } else {
                this.$store.dispatch("alert/error", "please select data and time").then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear");
                  }, 2000);
                });
              }
            }

            //     }, 2000);
            //   }, 2000);
            // });
          // }
        // })
      // .catch(()=>{
      //   this.isLoading = false;
      // })

    },
    stop() {
      this.$refs.datetimeform.reset();
      this.distancetravel = null;
      this.isplay = false;
      this.todate = null;
      this.toolbar = false;
     this.onClear()
      this.$nextTick(() => {
        this.initMap(this.ping[0].Gps);
  });
      // this.$refs.mytracking2.onAllview();
    },
    close14() {
      if (!this.isMobile()) {
        this.stop();
        this.toolbar = false;
        this.$refs.mytracking2.onAllview();
      }
      this.distancetravel = null;
      this.fromdate = null;
      this.todate = null;
      this.$refs.datetimeform.reset();
      this.dialog14 = false;
      const googleMapsComponent = this.$refs.mytracking2;
      if (googleMapsComponent && typeof googleMapsComponent.onClearall === 'function') {
        // Call the function in the dynamically loaded component
        googleMapsComponent.onClearall();
      } else {
        this.toolbar = false;
        console.log('Function not found in GoogleMaps component');
      }
    },
    handleMouseOver(item) {
      this.isMouseOver = true;
    },
    handleMouseclose() {
      this.isMouseOver = false;
    },
    getEventtype(item) {
      if (item.Deviceoperations) {
        var name = item.Deviceoperations[0].operation;
        if (name == "85") {
          return "VIOLATION RESET"
        } else if (name == "84") {
          return "ON DEMAND TEST"
        } else if (name == "82") {
          return "DEVICE RESTART"
        } else if (name == "83") {
          return "PICTURE CAPTURED"
        } else if (name == "88") {
          return "VEHICLE START"
        } else if (name == "89") {
          return "VEHICLE STOP"
        } else {
          return null
        }
      }
    },
    goCommand(item, command) {
      const Swal = require("sweetalert2");
      Swal.fire({
        title:
          "Are you sure? You want to Perform an action on " +
          item.Vehicle.regnum +
          "Vehicle",
        text: "You can't revert your action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          var cmdData = {
            devId: item.Vehicle.cbid,
            command: command,
            resp: "",
            refby: this.fleetuser.role == "office" ? this.fleetuser.officeId : this.fleetuser.dealerId,
            role: this.fleetuser.role
          };
          var data = JSON.stringify(cmdData);
          Vue.axios
            .post("https://robopower.xyz/app/commands", data)
            .then((res) => {
              if (res.data == "OPERATION DONE") {
                this.text = command + " " + "command sent successfully";
                this.alerts = true;
                this.$store.dispatch("alert/success", "OPERATION DONE").then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear")
                  }, 2000)
                })
              } else {
                this.$store.dispatch("alert/success", "OPERATION NOT DONE").then(() => {
                  setTimeout(() => {
                    this.$store.dispatch("alert/clear")
                  }, 2000)
                })
              }
            })
            .catch((error) => {
              this.$store.dispatch("alert/error", error).then(() => {
                setTimeout(() => {
                  this.$store.dispatch("alert/clear")
                }, 2000)
              })
            });
          this.$store.dispatch("alert/success", "Action Performed").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        } else {
          this.$store.dispatch("alert/error", "Your Request Cancelled").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      });
    },
    allopTrack(item) {
      this.devdata = item
      this.allopdialog = true;
    },
    allopdialogclose() {
      this.allopdialog = false;
    },
    eventTrackclose() {
      this.eventdata = null;
      this.eventmapdialog = false;
    },

    eventTrack(param) {
      this.eventdata = param;
      this.eventmapdialog = true;

    },
    eventclose() {
      // this.isSingle = false;
      this.search = "";
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.mobileview = true;
        return true
      } else {
        this.mobileview = false;
        return false
      }
    },
    idialog() {
      this.infodialog = true;
    },
    imgclose() {
      this.imgdialog = false;
      this.bacvalue = '';
      this.devid = '';
      this.rtime = '';
      this.result = '';
      this.imageBytes = '';
      this.imageloadingtext = "Loading...";
    },
    viewImage(data) {
      this.imageloadingtext = "Loading..."
      this.currentevent = data;
      this.imgdialog = true;
      var itemname = data.imgtime;
      // // var stname = itemname.replaceAll("/", "_");
      // // var ssname = stname.replaceAll(" ", "_");
      // // var smname = ssname.replaceAll(":", "_");
      // console.log(smname)
      this.bacvalue = data.bacvalue;
      this.devid = data.devid;
      this.rtime = data.rtime;
      this.result = data.passfailstatus;
      Vue.axios
        .get(
          "https://robopower.xyz/app/getImage/getb64v4?deviceId=" +
          data.controlId +
          "&Image=" +
          itemname
        )
        .then((res) => {
          // check Image at exact time
          // console.log(res.data)
          if (res.data) {
            if (res.data != 'noimagefound') {
              this.imageloadingtext = "Loading..."
            } else {
              this.imageloadingtext = "No Image Found"
            }
            this.imageBytes = res.data;
          } else {
            this.$store.dispatch("alert/error", "No Image Found").then(() => {
              setTimeout(() => {
                this.$store.dispatch("alert/clear")
              }, 2000)
            })
            // End second check
          }
        })
        .catch((error) => {
          this.$store.dispatch("alert/error", error).then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        });
    },
    onLogout() {
      this.$store.dispatch("authentication/fleetlogout");
    },
    // onLoadevents(vehicle) {
    //   this.singleVehicle = vehicle.Vehicle
    //   Vue.axios
    //     .get("https://robopower.xyz/v2/eeprom/gethundredlogs?cbid=" + vehicle.Vehicle.cbid)
    //     .then((res) => {
    //       if (res.data != null) {
    //         this.selectedVehicleevents =res.data.sort(
    //           (a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf()
    //         );
    //         this.selectedVehicleevents = this.selectedVehicleevents.reverse();
    //       }
    //     });
    //   this.selectedVehiclemap = null;
    // },

    onLoadevents(vehicle) {
      this.singleVehicle = vehicle.Vehicle;
      // console.log("Selected Vehicle:", this.singleVehicle);
      Vue.axios
        .get("https://robopower.xyz/v2/eeprom/gethundredlogs?cbid=" + vehicle.Vehicle.cbid)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            // Sort and reverse logs
            this.selectedVehicleevents = res.data
              .sort((a, b) => moment(a.rtime).valueOf() - moment(b.rtime).valueOf())
              .reverse();
            // Calculate rating for this vehicle
            this.calculateRating(this.selectedVehicleevents, vehicle.Vehicle.cbid);
          } else {
            this.selectedVehicleevents = []; // Clear logs if no data
            this.vehicleRatings[vehicle.Vehicle.cbid] = 0; // Reset specific vehicle rating
          }
        })
        .catch((err) => {
          console.error("Error fetching logs:", err);
        });
    },

calculateRating(events, vehicleId) {
  if (events && events.length > 0) {
    // Step 1: Count logs for each event type
    const passLogs = events.filter((event) => event.passfailstatus === "1").length;
    const failLogs = events.filter((event) => event.passfailstatus === "0").length;
    const bypassLogs = events.filter((event) => event.typeofevent === "4").length;

    // Step 2: Calculate total logs
    const totalLogs = events.length;

    // Step 3: Calculate proportions for each event type
    const passProportion = passLogs / totalLogs;
    const failProportion = failLogs / totalLogs;
    const bypassProportion = bypassLogs / totalLogs;

    // Step 4: Calculate dynamic criticality
    const passCriticality = 1 - passProportion;
    const failCriticality = 1 - failProportion;
    const bypassCriticality = 1 - bypassProportion;

    // Step 5: Assign weights based on criticality
    const passWeight = passCriticality; // Positive impact
    const failWeight = -failCriticality; // Negative impact
    const bypassWeight = -bypassCriticality; // Slightly negative impact

    // Step 6: Calculate weighted score
    const weightedScore =
      passProportion * passWeight +
      failProportion * failWeight +
      bypassProportion * bypassWeight;

    // Step 7: Calculate minimum and maximum possible weighted scores
    const maxWeightedScore = passWeight; // Maximum occurs when all events are pass
    const minWeightedScore = failWeight + bypassWeight; // Minimum occurs when all events are fail or bypass

    // Step 8: Normalize the score to a 5-star rating dynamically
    let rating = ((weightedScore - minWeightedScore) / (maxWeightedScore - minWeightedScore)) * 5;
    rating = Math.max(0, Math.min(5, rating)); // Ensure rating is between 0 and 5

    // Step 9: Round to nearest half-star
    const finalRating = Math.round(rating * 2) / 2;

    // Step 10: Assign the final rating to the vehicle
    this.vehicleRatings[vehicleId] = finalRating;

    // Console logs for debugging and understanding
    // console.log("Rating Calculation for Vehicle:", vehicleId, " ");
    // console.log("Total Logs:", totalLogs);
    // console.log("Pass Logs:", passLogs, "| Proportion:", passProportion.toFixed(2));
    // console.log("Fail Logs:", failLogs, "| Proportion:", failProportion.toFixed(2));
    // console.log("Bypass Logs:", bypassLogs, "| Proportion:", bypassProportion.toFixed(2));
    // console.log("Pass Criticality:", passCriticality.toFixed(2));
    // console.log("Fail Criticality:", failCriticality.toFixed(2));
    // console.log("Bypass Criticality:", bypassCriticality.toFixed(2));
    // console.log("Weighted Score:", weightedScore.toFixed(2));
    // console.log("Min Weighted Score:", minWeightedScore.toFixed(2));
    // console.log("Max Weighted Score:", maxWeightedScore.toFixed(2));
    // console.log("Normalized Rating (0-5):", rating.toFixed(2));
    // console.log("Final Rating (Rounded):", finalRating);
  } else {
    // If no logs, set rating to 0
    this.vehicleRatings[vehicleId] = 0;
    console.log("No logs found for Vehicle:", vehicleId, "| Rating set to 0");
  }
},

//     onLoadevents(item) {
//       // this.isSingle = !this.isSingle;
//       // if (this.isSingle) {
//         this.logstext = "Loading....";
//         // this.search = item.Vehicle.regnum;
//         var newProm = new Promise((resolve,reject)=>{
//               if(item.Vehicle.sno){
//                 var devicetimezone
//                 Vue.axios
//             .get("https://robopower.xyz/us/cadevices/getuniquedevice?sno="+ item.Vehicle.sno)
//             .then((resp) => {
//               if(resp.data){
//                 if(resp.data[0].timezone == "GMT"){
//                   if(resp.data[0].tagstate == "INDIA"){
//                     devicetimezone = "Asia/Kolkata"
//                   } else if(resp.data[0].tagstate == "CA"){
//                     devicetimezone = "America/Los_Angeles"
//                   } else if(resp.data[0].tagstate == "TX"){
//                     devicetimezone = "America/Chicago"
//                   } else if(resp.data[0].tagstate == "TX"){
//                     devicetimezone = "America/Chicago"
//                   } else if(resp.data[0].tagstate == "WI"){
//                     devicetimezone = "America/Chicago"
//                   } else if(resp.data[0].tagstate == "AL"){
//                     devicetimezone = "America/Chicago"
//                   } else{
//                     devicetimezone = "GMT"
//                   }
//                   resolve(devicetimezone)
//                   // })
//                 }
//               } else{
//                 reject("Device Not Added in the List")
//               }
    
//             })
//               }
//             })
//             newProm.then((result)=>{
//               Vue.axios
//           .get("https://robopower.xyz/v2/eeprom/gethundredlogs?cbid=" + item.Vehicle.cbid)
//           .then((response) => {
//             var nPromise = new Promise((res, rej) => {
//               if (response.data != null) {
//                 this.logstext = "";
//                 var arr2 = response.data;
//                 var newArr = [];
//                 arr2.map((element) => {
//                   var eventType, Result, Bacvalue;
//                   if (element.typeofevent == 254) {
//                     //initialtest
//                     if (element.randominitial == 2 || element.randominitial == 0) {
//                       eventType = "INITIAL TEST"
//                     } else if (element.randominitial == 3 || element.randominitial == 1) {
//                       eventType = "RANDOM TEST"
//                     } else {
//                       eventType = "NA"
//                     }
//                     if (element.passfailstatus == 1) {
//                       Result = "PASS"
//                     } else if (element.passfailstatus == 0) {
//                       Result = "FAIL"
//                     } else {
//                       Result = " "
//                     }
//                   } else if (element.typeofevent == 15) {
//                     if (element.enginerunstate == 0) {
//                       eventType = "ENGINE OFF"
//                     } else if (element.enginerunstate == 1) {
//                       eventType = "ENGINE ON"
//                     } else {
//                       eventType = "NA"
//                     }
//                   } else if (element.typeofevent == 34) {
//                     eventType = "ENGINE_NOT_STARTED";
//                   }
//                    else if (element.typeofevent == 65) {
//                     eventType = "INSUFFICIENTVOLUME";
//                   } else if (element.typeofevent == 101) {
//                     eventType = "PICTURE_CAPTURED";
//                   } else if (element.typeofevent == 4) {
//                     eventType = "BYPASS";
//                   } else {
//                     console.log("")
//                   }

//                   if (element.bacvalue != 255) {
//                     Bacvalue = parseFloat(element.bacvalue) / 1000;
//                   } else {
//                     Bacvalue = "";
//                   }
//                   var convertedDateTime 
//                   if(result != "GMT"){

// convertedDateTime = moment.utc(element.rtime, 'MM/DD/YYYY HH:mm:ss').tz(result).format('MM/DD/YYYY HH:mm:ss');
// } else{
//   convertedDateTime = element.rtime;
// }
//                   var obj = {
//                     id: element.id,
//                     bacvalue: Bacvalue,
//                     rtime: convertedDateTime,
//                     devid: element.devid,
//                     controlId: element.controlId,
//                     passfailstatus: Result,
//                     typeofevent: eventType,
//                     lat: element.lat,
//                     lon: element.lon,
//                     imgtime:element.rtime
//                   }

//                   newArr.push(obj)
//                 })
//                 res({ "data": newArr })
//               } else {
//                 this.logstext = "NO DATA FOUND";
//                 this.items = [];
//                 rej("no data found")
//               }
//             });
//             nPromise.then((res) => {
//               if (res != "no data found") {
//                 this.items = res.data
//                 //open Bar
//               }
//             })
//           })

//             })
        
//       // } else {
//       //   this.items = [];
//       //   this.search = "";
//       // }
//     },
    clearSearch() {
      this.search = '';
      this.mydata = null;
      this.isFiltered = false;
      if (!this.isMobile) {
        this.$refs.mytracking2.onAllview();
      }
    },

    calCount(data) {
      var countPromise = new Promise((res, rej) => {
        var moving = 0;
        var offline = 0;
        var online = 0;
        var fails = 0;
        var passes = 0;
        var bypass = 0;
        var overspeed = 0;
        var Idle = 0;
        if (data.length > 0) {
          this.all = data.length;
          data.map((res) => {
            if(res.Gps.speed != ""){
              if (parseFloat(res.Gps.speed) > 80.0) {
              overspeed = overspeed + 1
            }
            }
            if(res.Engine.enginerunstate != ""){
              if(res.Gps.speed != ""){
            if (res.Engine.enginerunstate == 1 && parseFloat(res.Gps.speed) == 0.0) {
              Idle = Idle + 1
            }
          }else{
            if (res.Engine.enginerunstate == 1) {
              Idle = Idle + 1
            }
          }
          }
          if(res.Engine.enginerunstate != ""){
            if (res.Engine.enginerunstate == "0") {
              offline = offline + 1
            } else if (res.Engine.enginerunstate == "1") {
              if(res.Gps.speed != ""){
              if(parseFloat(res.Gps.speed) > 2.0){
              moving = moving + 1;
              }else{
                online = online + 1  
              }
            } else{
              online = online + 1
            }
            } else {
              online = online + 1
            }
          } else{
            offline = offline + 1
          }
            if(res.Event.typeofevent != ""){
            if (res.Event.typeofevent == "4") {
              bypass = bypass + 1;
            } else {
              if (res.Event.passfailstatus == "1") {
                passes = passes + 1
              } else {
                fails = fails + 1
              }
            }
          }
          })
          res({ "status": "ok", "moving": moving, "offline": offline, "online": online, "fails": fails, "passes": passes, "bypass": bypass, "Idle": Idle, "overspeed": overspeed })
        }
      })
      countPromise.then((res) => {
        this.moving = res.moving;
        this.offline = res.offline;
        this.online = res.online;
        this.fails = res.fails;
        this.passes = res.passes;
        this.bypass = res.bypass;
        this.Idle = res.Idle;
        this.overspeed = res.overspeed;

      })

    },
    calctime(data) {
      var data1 = data;
      if (data1.gtime != "") {
        var before = moment(data1.gtime);
        var present = moment(moment().format("MM/DD/YYYY HH:mm:ss"));
        var calculate = present.diff(before, "hours");
        if (calculate > 24) {
          var calculate2 = present.diff(before, "days");
          return calculate2 + " days Ago.";
        } else {
          var calculate3 = moment.duration(present.diff(before));
          var hours = parseInt(calculate3.asHours());
          var minutes = parseInt(calculate3.asMinutes()) - hours * 60;
          return hours + "hr " + minutes + "min Ago.";
        }
      } else {
        return "NA";
      }
    },
    initMap(vehicle) {
      // console.log(vehicle)
      mapboxgl.accessToken = "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";

      const map = new mapboxgl.Map({
        container: `map-${vehicle.gtime}`,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [parseFloat(vehicle.lon), parseFloat(vehicle.lat)],
        zoom: 12
      });

      new mapboxgl.Marker()
        .setLngLat([parseFloat(vehicle.lon), parseFloat(vehicle.lat)])
        .addTo(map);
    },



    InvoicePdfDownload(invoice){
      this.invoicesreport =invoice;
    },

  setActiveComponent(component) {
    this.currentComponent = component;
    this.activeComponent = component;
  },




    openSettings(vehicle){
      // console.log(vehicle);
      this.vehicleSettings =vehicle;
      this.selectedVehiclemap = null;
  this.selectedVehicleevents = null;
    },
    openFacerecognition(vehicle){
      // console.log(vehicle);
      this.facerecognitionSettings =vehicle;
      this.selectedVehiclemap = null;
  this.selectedVehicleevents = null;
    },
    openMap(vehicle) {
  // console.log(vehicle);
  this.ping = [vehicle];
  this.selectedVehiclemap = vehicle.Gps;
  this.selectedVehicleevents = null; // Close events if map is opened
  this.vehicleSettings =null;

  // Initialize the map after the DOM is updated
  this.$nextTick(() => {
    this.initMap(vehicle.Gps); // Pass the correct vehicle object to the map
  });
},
    filteredData(val) {
      this.isFiltered = true;
      if (val == "fail") {
        var fails = this.fullclients.filter((item) => {
          return item.Event.passfailstatus == "0"
        });
        if (fails.length > 0) {
          this.fullclients = fails
        } else {
          this.$store.dispatch("alert/error", "No Drunk Drivers").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      } else if (val == "bypass") {
        var bypass = this.fullclients.filter((item) => {
          return item.Event.typeofevent == "4"
        });
        if (bypass.length > 0) {
          this.fullclients = bypass
        } else {
          this.$store.dispatch("alert/error", "No Bypassed Vehicles").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      } else if (val == "overspeed") {
        var overspeed = this.fullclients.filter((item) => {
          return parseFloat(item.Gps.speed) > 60
        });
        if (overspeed.length > 0) {
          this.fullclients = overspeed
        } else {
          this.$store.dispatch("alert/error", "No OverSpeed Vehicles").then(() => {
            setTimeout(() => {
              this.$store.dispatch("alert/clear")
            }, 2000)
          })
        }
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    addformatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    getmonthList(id) {
      //initial install date
      const startDatetime = moment(id.installDate, "MM/DD/YYYY HH:mm:ss");

      // Array to store objects
      const dataList = [];

      // Number of objects to create

      const endDat = moment(
        moment().format("MM/DD/YYYY HH:mm:ss"),
        "MM/DD/YYYY HH:mm:ss"
      );
      const monthsDiff = endDat.diff(startDatetime, "months");

      const numberOfObjects = Math.round(monthsDiff + 1) / 2;

      // Loop to create objects with incremented datetime and push data
      for (let i = 1; i < numberOfObjects + 1; i++) {
        const currentDatetime = startDatetime.clone().add(2 * i - 2, "months");
        const endedDatetime = startDatetime.clone().add(2 * i, "months");
        var fData = [];
        this.logs.forEach((item) => {
          1;
          const checkDate = moment(item.rtime, "MM/DD/YYYY HH:mm:ss");
          const isBetween = checkDate.isBetween(currentDatetime, endedDatetime);
          if (isBetween) {
            fData.push(item);
          }
        });
        const dataObject = {
          id: i + 1,
          startDate: currentDatetime.format("MM/DD/YYYY HH:mm:ss"),
          endDate: endedDatetime.format("MM/DD/YYYY HH:mm:ss"),
          data: fData,
        };
        dataList.push(dataObject);
      }

      return dataList;
    },

  },
  computed: {

    ...mapGetters(["logs"]),
    ...mapGetters(["oledDevices"]),
    minToDate() {
      // Set the minimum allowed date for "To Date" picker based on the selected "From Date"
      return this.fromdate ? moment(this.fromdate).format("YYYY-MM-DDTHH:mm:ss") : null;
    },
    computedDateFormatted() {
      return this.formatDate(this.editedItem.dob);
    },
    computedaddDateFormatted() {
      return this.addformatDate(this.editedItem.dob);
    },
    resultQuery() {
      // console.log(this.fullclients)
      if (this.search) {
        return this.fullclients.filter((item) => {
          return item.Vehicle.regnum.includes(this.search);
        });
      } else {
        return this.fullclients;
      }

    },
    eventsQuery() {
      if (this.eventssearch) {
        return this.items.filter((item) => {
          return JSON.stringify(item).includes(this.eventssearch);
        });
      } else {
        return this.items;
      }

    },
  },
  created() {
    this.requestNotificationPermission();
  },
  beforeMount() {
      Vue.axios.get("https://robopower.xyz/us/cadealerpayments/checkdealerpayment?dealer=" + 
      this.fleetuser.dealerId).then((res)=>{
        if(res.data == "1"){
          this.grantAccess = true;
          // console.log("hiii1")
        }else if(res.data == "0" ){
          console.log(res.data);
          Vue.axios.get('https://robopower.xyz/us/cadealers/getuniquedealership?dealerId=' + this.fleetuser.dealerId)
                .then((res2) => {
                    if (res2.data != null) {
                        // this.selectedDealer = res.data[0];
                        console.log(res2.data[0].id, 'selected dealer')
                        // this.dialog2 = true;
                        Vue.axios.get('https://robopower.xyz/us/cadealerpayments/getdealershipinvoices?dealershipid=' + res2.data[0].id)
                            .then((resp) => {
                                if (resp.data != null) {
                                    this.dealershipinvoices = resp.data;
                                    let sum = resp.data.filter(transaction => transaction.paymentstatus === 'unpaid').reduce((accumulator, currentValue) => {
                                        return accumulator + parseFloat(currentValue.total);
                                    }, 0);
                                    this.payingamount = sum;
                                    console.log(this.payingamount)
                                      const latestCreatedDate = resp.data.reduce((latest, entry) => {
                                        const createdDate = moment(entry.created_date, 'MM/DD/YYYY');
                                        return createdDate.isAfter(latest) ? createdDate : latest;
                                      }, moment('01/01/1900', 'MM/DD/YYYY'));
                                      const today = moment();
                                      const differenceInDays = today.diff(latestCreatedDate, 'days');

                                      // Check if the difference is greater than 10 days
                                      if (differenceInDays > 10) {
                                        console.log('Above 10 days');
                                    this.grantAccess = false;
                                    this.header = "Payment Pending"
                                        this.message="Your payment is currently pending";
                                        this.createIndent();
                                      } else {
                                        console.log('Within 10 days');
                                        this.grantAccess = true;
                                        var daysdef = 10-differenceInDays;
                                        this.warningmessage = "Your payment is currently pending. Please complete the payment of Rs."+ this.payingamount+" within "+ daysdef+"days to avoid any interruptions. For assistance, feel free to contact customer care.Call: +91 7815923620,+91 7815923621"
                                        
                                      }

                                }
                            })
                    }

                })

          
        }
        else{
          this.grantAccess = false;
          this.header = "Dealer Not Authorized"
          this.message = "Devices Not Assigned to the Dealer"
        }
      });
     
    
  },
  mounted() {
    // console.log('Dashboard mounted, available components:', this.components.map(c => c.name));
    this.currentComponent = 'ServiceCustomerInfo';

  this.loading = true;
    Eventemit.$on("distancetravelled", this.handledistance);
    this.$store.dispatch('fetchOLEDDevices');
    if (this.isplay == false) {
      setInterval(() => {
        this.onRefresh();
      }, 10000);
    }
    Vue.axios
      .get("https://robopower.xyz/app/v2data/getdealerlatestupdates3?dealerId=" + this.fleetuser.dealerId)
      .then(response => {
        if (response.data != null) {
          this.fullclients = response.data;
          this.calCount(response.data);
          this.loading = false;
        } else{
          this.loading = false;
        }
      });
    setTimeout(() => {
      setInterval(() => {
        Vue.axios
          .get("https://robopower.xyz/app/v2data/getdealerlatestupdates3?dealerId=" + this.fleetuser.dealerId)
          .then(response => {
            if (response.data != null) {
              if (this.isFiltered) {
                this.fullclients = this.fullclients;
              } else {
                this.fullclients = response.data;
              }
              this.calCount(response.data);

            }
          });
      }, 10000)
    }, 1000)
    Vue.axios
      .get("https://robopower.xyz/us/cadealers/getuniquedealer?dealerId=" + this.fleetuser.dealerId)
      .then(response => {
        if (response.data != null) {
          this.profiledata = response.data[0];

        }
      })
      window.addEventListener('resize', this.updateScreenSize);
      // After chart is mounted, you can trigger rendering, if necessary
  this.$nextTick(() => {
    // Example of ensuring chart is ready
    if (this.chartInstance) {
      // Do something after the chart is rendered
    }
  });  
    },
    
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
  components: {
    EventTrack,
    SettingsTrack,
    Facerecognition,
    FullLiveTrack,
    Routeplay,
    Googlemaps,
    Barchart,
    SpeedChart,
    lastoperations,
    AlcoholRecordsChart,
    Preloader,
    ServiceCustomerInfo,
    ServiceInvoices,
    ServiceTransactions,
    ServiceAppointments,
  },
};
</script>

<style scoped>
.full-screen-container {
  background-color: aliceblue;
}
.odometer {
  font-size: 20px;
  margin-top: 25px;
  position: absolute;
  width: 80px;
  z-index: 1;
  left: calc(128% - 136px);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-template-rows: repeat(2, 20px);
  gap: 1px;
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
  margin-left: 5%;
}

.grid-item {
  text-align: center;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  grid-template-rows: repeat(0, 20px);
  /* gap: 1px; */
  border: 1px solid black;
  border-radius: 10px;
  padding: 110px;
  /* height: 50%; */
}

.grid-item1 {
  border: 1px solid black;
  text-align: center;
  font-size: x-large;
}

/* .max-v-list-height {
  max-height: screenHeight;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-wrap: normal;
} */



.max-v-list-height2 {
  max-height: 730px;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-wrap: normal;
}

.line {
  width: 80%;
  height: 5px;
  margin-left: 10%;
  background-color: #64b5f6;
}

#scrollable-div {
  width: 93%;
  height: 100px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  margin-left: 5%
}

.box {
  flex-shrink: 0;
  width: 100px;
  height: 90px;
  margin-right: 10px;
  border: 3px solid #546e7a;
  border-radius: 10%;
}

.pointer-image {
  cursor: pointer;
  /* Change cursor to pointer */
}
.custom-table {
  max-width: 600px; /* Adjust the width as needed */
  margin: auto; /* Center the table horizontally */
}
.striped-highlight {
  max-width: 400px; /* Adjust the width as needed */
  margin: auto;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
.map-panel, .events-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 1100px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
  overflow: hidden;
  z-index: 1000;
  border-radius: 12px 0 0 12px;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.map-panel2, .events-panel2 {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
  overflow: hidden;
  z-index: 1000;
  border-radius: 12px 0 0 12px;
  transition: transform 0.3s ease;
  transform: translateX(0);
}

.map-panel .map, .events-panel .events-content {
  height: calc(100% - 60px);
  padding: 20px;
}
.map-panel2 .map, .events-panel2 .events-content {
  height: calc(100% - 60px);
  padding: 20px;
}
.scroll-container {
  width: 100%; /* Adjust width as needed */
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  background-color: #f5f5f5; /* Optional: Background color */
  border: 1px solid #ddd; /* Optional: Border for better visibility */
  padding: 10px 0;
}

.scroll-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-animation 35s linear infinite;
}

@keyframes scroll-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.services-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
}

.topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.topnav-left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.page-title {
  font-size: 1.5rem;
  margin: 0;
  color: #000000;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f1f3f4;
  border-radius: 8px;
  padding: 5px 10px;
  width: 300px;
}

.search-bar input {
  border: none;
  outline: none;
  background: transparent;
  font-size: 1rem;
  width: 100%;
}

.search-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.topnav-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #000000;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.main-container {
  display: flex;
  flex: 1;
}

.sidenav {
  width: 250px;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex-shrink: 0;
  transition: width 0.3s ease;
}

.sidenav-collapsed {
  width: 60px;
}

.sidenav-header {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.sidenav-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
}

.sidenav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.sidenav ul li {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  font-size: 0.9rem;
  position: relative;
}

.sidenav ul li:hover {
  background-color: #e9ecef;
}

.sidenav ul li.active {
  background-color: #e9ecef;
  border-left: 4px solid #1abc9c;
}

.sidenav ul li.active a {
  color: #2c3e50;
  font-weight: 600;
}

.sidenav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.content {
  flex-grow: 1;
  padding: 40px;
  background-color: #ffffff;
}

.content h1 {
  margin-top: 0;
  font-size: 2rem;
  color: #2c3e50;
}

.content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}
.fill-width {
  width: 100%;
}

.v-col {
  padding: 20px;
}

/* Make each tab take equal width */
:deep(.v-tab) {
  width: 25%;
}

:deep(.v-tabs-bar) {
  width: 100%;
}
/* Ensure the services content is scrollable and fits within the drawer */
.services-content {
  overflow-y: auto;
  max-height: calc(100vh - 200px); /* Adjust based on your layout */
  padding: 16px;
}

/* Make the navigation drawer full height */
.v-navigation-drawer {
  height: 100vh;
}
</style>

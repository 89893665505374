<template>
  <v-container>
    <v-card class="pa-5" elevation="2">
      <v-card-title class="text-h5">Cancellation and Refund Policy</v-card-title>
      <v-card-text>
        <ul>
          <li>Alcobrake does not accept cancellations or provide refunds as all sales are made offline and finalized at the time of purchase.</li>
          <li>We ensure thorough product demonstration and verification before finalizing any sale.</li>
          <li>In case of defective or damaged products, please contact us immediately for assistance.</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CancellationRefundPolicy"
};
</script>

<style scoped>
ul {
  padding-left: 20px;
}
li {
  margin-bottom: 10px;
}
</style>

<template>
  <v-container>
    <v-card class="pa-5" elevation="2">
      <v-card-title class="text-h5">Contact Us</v-card-title>
      <v-card-text>
        <p>You may contact us using the information below:</p>
        <ul>
          <li><strong>Merchant Legal Entity Name:</strong> Alcobrake Private Limited</li>
          <li><strong>Registered Address:</strong> Fifth Floor of Ruby Tower, H. No. 198/9/3/4/17 & 18, Silicon Valley, Madhapur, Hi-tech City Hyderabad, TELANGANA 500034</li>
          <li><strong>Operational Address:</strong> Fifth Floor of Ruby Tower, H. No. 198/9/3/4/17 & 18, Silicon Valley, Madhapur, Hi-tech City Hyderabad, TELANGANA 500034</li>
          <li><strong>Telephone No:</strong> 8977285706</li>
          <li><strong>E-Mail ID:</strong> <a href="mailto:saireddy@alcobrake.com">saireddy@alcobrake.com</a></li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ContactUs"
};
</script>

<style scoped>
ul {
  padding-left: 20px;
}
li {
  margin-bottom: 10px;
}
</style>

<template>
  <v-container>
    <v-card class="pa-5" elevation="2">
      <v-card-title class="text-h5">Privacy and Policy</v-card-title>
      <v-card-text>
        <p>At Alcobrake, we value your privacy. This policy explains how we handle your personal information:</p>
        <ul>
          <li>We collect only necessary customer details for offline transactions and support.</li>
          <li>Your information is never shared, sold, or disclosed to third parties except as required by law.</li>
          <li>We may use your contact details to provide support, product updates, and service-related communication.</li>
          <li>We ensure reasonable security measures to protect your data from unauthorized access.</li>
          <li>If you have any concerns about your privacy, please contact us at <a href="mailto:saireddy@alcobrake.com">saireddy@alcobrake.com</a>.</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyAndPolicy"
};
</script>

<style scoped>
ul {
  padding-left: 20px;
}
li {
  margin-bottom: 10px;
}
</style>

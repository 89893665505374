<template>
  <v-container>
    <v-card class="pa-5" elevation="2">
      <v-card-title class="text-h5">Shipping Policy</v-card-title>
      <v-card-text>
        <p>Since Alcobrake operates solely through offline sales, the following shipping terms apply:</p>
        <ul>
          <li>We do not process online orders or deliveries.</li>
          <li>Products are directly handed over to customers upon purchase.</li>
          <li>For bulk or special orders, delivery arrangements can be discussed with our sales team.</li>
          <li>Customers are responsible for picking up their products from designated locations as agreed during purchase.</li>
          <li>We strive to process and ship all orders as quickly as possible.</li>
    <li><strong>Estimated Delivery Time:</strong></li>
    <ul>
        <li><strong>Minimum:</strong> 1 week</li>
        <li><strong>Maximum:</strong> 2 weeks</li>
    </ul>
    <li>Delivery times may vary based on location, shipping carrier delays, or unforeseen circumstances.</li>
    <li>If you have any questions regarding your shipment, please contact our support team.</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ShippingPolicy"
};
</script>

<style scoped>
ul {
  padding-left: 20px;
}
li {
  margin-bottom: 10px;
}
</style>

<template>
    <div class="services-page">
      <!-- Top Navigation Bar -->
      <div class="topnav">
        <div class="topnav-left">
          <h1 class="page-title">Alcobrake</h1>
          <div class="search-bar">
            <input type="text" placeholder="Search..." />
            <span class="search-icon">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="#FF6B6B" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
              </svg>
            </span>
          </div>
        </div>
        <div class="topnav-right">
          <!-- Live Mode Dropdown -->
          <div class="dropdown">
            <button class="dropbtn">
              <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="#4ECDC4" d="M17,9H7V7H17M17,13H7V11H17M14,17H7V15H14M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z" />
              </svg>
              Live Mode
            </button>
            <div class="dropdown-content">
              <a href="#">Test Mode</a>
            </div>
          </div>
          <!-- Icons -->
          <span class="icon">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="#FFD166" d="M10,21H14A2,2 0 0,1 12,23A2,2 0 0,1 10,21M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M17,11A5,5 0 0,0 12,6A5,5 0 0,0 7,11V18H17V11M12,9A2,2 0 0,1 14,11A2,2 0 0,1 12,13A2,2 0 0,1 10,11A2,2 0 0,1 12,9Z" />
            </svg>
          </span>
          <span class="icon">
            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="#6B5B95" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
            </svg>
          </span>
        </div>
      </div>
  
      <!-- Main Content Container -->
      <div class="main-container">
        <!-- Sidenav -->
        <div class="sidenav" :class="{ 'sidenav-collapsed': isSidenavCollapsed }">
          <div class="sidenav-header">
            <h2>Services</h2>
          </div>
          <ul>
            <li :class="{ active: activeComponent === 'ServiceCustomerInfo' }" @click="activeComponent = 'ServiceCustomerInfo'">
              <a>Customer Info</a>
            </li>
            <li :class="{ active: activeComponent === 'ServiceInvoices' }" @click="activeComponent = 'ServiceInvoices'">
              <a>Invoices</a>
            </li>
            <li :class="{ active: activeComponent === 'ServiceTransactions' }" @click="activeComponent = 'ServiceTransactions'">
              <a>Transactions</a>
            </li>
            <li :class="{ active: activeComponent === 'ServiceAppointments' }" @click="activeComponent = 'ServiceAppointments'">
              <a>Appointments</a>
            </li>
          </ul>
        </div>
  
        <!-- Main Content -->
        <div class="content">
          <ServiceCustomerInfo v-if="activeComponent === 'ServiceCustomerInfo'" />
          <ServiceInvoices v-if="activeComponent === 'ServiceInvoices'" />
          <ServiceTransactions v-if="activeComponent === 'ServiceTransactions'" />
          <ServiceAppointments v-if="activeComponent === 'ServiceAppointments'" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ServiceCustomerInfo from './serviceCustomerInfo';
  import ServiceInvoices from './serviceInvoices';
  import ServiceTransactions from './serviceTransactions';
  import ServiceAppointments from './serviceAppointments';
  
  export default {
    name: 'ServicesPage',
    components: {
      ServiceCustomerInfo,
      ServiceInvoices,
      ServiceTransactions,
      ServiceAppointments,
    },
    
    data() {
      return {
        fleetuser: JSON.parse(localStorage.getItem("fleetuser")),
        activeComponent: 'ServiceCustomerInfo',
        isSidenavCollapsed: false,
      };
      
    },
    methods:{

    },
    mounted(){
      console.log(this.fleetuser)
    }
  };
  </script>
  
  <style scoped>
  .services-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #ffffff;
  }
  
  /* Top Navigation Bar */
  .topnav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .topnav-left {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .page-title {
    font-size: 1.5rem;
    margin: 0;
    color: #000000;
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    background-color: #f1f3f4;
    border-radius: 8px;
    padding: 5px 10px;
    width: 300px;
  }
  
  .search-bar input {
    border: none;
    outline: none;
    background: transparent;
    font-size: 1rem;
    width: 100%;
  }
  
  .search-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .topnav-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  /* Dropdown */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropbtn {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #000000;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Main Container for Sidenav and Content */
  .main-container {
    display: flex;
    flex: 1;
  }
  
  /* Side Navigation Bar */
  .sidenav {
    width: 250px;
    background-color: #f8f9fa;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex-shrink: 0;
    transition: width 0.3s ease;
  }
  
  .sidenav-collapsed {
    width: 60px;
  }
  
  .sidenav-header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .sidenav-header h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c3e50;
  }
  
  .sidenav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
  }
  
  .sidenav ul li {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    font-size: 0.9rem;
    position: relative;
  }
  
  .sidenav ul li:hover {
    background-color: #e9ecef;
  }
  
  .sidenav ul li.active {
    background-color: #e9ecef;
    border-left: 4px solid #1abc9c;
  }
  
  .sidenav ul li.active a {
    color: #2c3e50;
    font-weight: 600;
  }
  
  .sidenav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
  }
  
  /* Main Content */
  .content {
    flex-grow: 1;
    padding: 40px;
    background-color: #ffffff;
  }
  
  .content h1 {
    margin-top: 0;
    font-size: 2rem;
    color: #2c3e50;
  }
  
  .content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  </style>
<template>
  <v-container>
    <!-- Summary Cards -->
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="summary-card total-card">
          <v-card-title class="card-title">Total Amount</v-card-title>
          <v-card-text class="card-text">
            <h2>${{ totalAmount }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="summary-card pending-card">
          <v-card-title class="card-title">Pending Invoices</v-card-title>
          <v-card-text class="card-text">
            <h2>${{ openAmount }}</h2>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="summary-card paid-card">
          <v-card-title class="card-title">Paid Amount</v-card-title>
          <v-card-text class="card-text">
            <h2>${{ paidAmount }}</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Filter Controls -->
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          v-model="selectedCustomer"
          :items="customerOptions"
          label="Filter by Customer"
          clearable
          @change="applyFilters"
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="selectedStatus"
          :items="statusOptions"
          label="Filter by Status"
          clearable
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col cols="12" md="4">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedMonth"
              label="Filter by Month"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearMonthFilter"
              outlined
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedMonth"
            type="month"
            @input="applyFilters"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Invoice Table -->
    <v-card class="pa-5" elevation="4">
      <v-card-title class="text-h5 font-weight-bold text-center">
        Invoice List
      </v-card-title>

       <!-- Loading Spinner -->
       <v-row v-if="loading" class="justify-center">
        <v-col cols="12" class="text-center">
          <v-circular-progress
            indeterminate
            color="primary"
          ></v-circular-progress>
          <h1 class="mt-2">Loading invoices...</h1>
        </v-col>
      </v-row>

      <v-simple-table class="invoice-table">
        <thead class="table-header">
          <tr>
            <th class="text-center">Client Name</th>
            <th class="text-center">Email</th>
            <th class="text-center">Amount ($)</th>
            <th class="text-center">Status</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) in filteredInvoices" :key="index" class="invoice-row">
            <td>{{ invoice.client }}</td>
            <td>{{ invoice.email }}</td>
            <td>${{ invoice.amount }}</td>
            <td>
              <v-chip :color="getStatusColor(invoice.status)" small>
                {{ invoice.status }}
              </v-chip>
            </td>
            <td class="action-buttons">
              <v-btn 
                v-if="invoice.status.toLowerCase() === 'open'" 
                color="primary" 
                small
                @click="openPaymentLink(invoice.hosted_invoice_url)"
              >
                Pay Now
              </v-btn>

              <v-btn 
                v-else-if="invoice.status.toLowerCase() === 'paid'" 
                color="green" 
                small
                @click="downloadInvoice(invoice)"
              >
                Download
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <!-- No Invoices Message -->
      <v-row v-if="!loading && filteredInvoices.length === 0">
        <v-col cols="12" class="text-center">
          <v-alert type="info">
            No invoices found.
          </v-alert>
        </v-col>
      </v-row>

    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import axios from 'axios'

export default {
  data() {
    return {
      totalAmount: 0,
      openAmount: 0,
      paidAmount: 0,
      invoices: [],
      selectedCustomer: null,
      selectedStatus: "All",
      selectedMonth: null,
      dateMenu: false,
      customerOptions: [],
      statusOptions: ["All", "Paid", "Open"],
      loading: false, // Add loading state
    };
  },
  computed: {
    filteredInvoices() {
      return this.invoices.filter((invoice) => {
        const matchesCustomer =
          !this.selectedCustomer || invoice.client === this.selectedCustomer;

        const matchesStatus =
          this.selectedStatus === "All" || !this.selectedStatus || 
          invoice.status.toLowerCase() === this.selectedStatus.toLowerCase();

        const matchesMonth =
          !this.selectedMonth ||
          new Date(invoice.date).toISOString().slice(0, 7) === this.selectedMonth.slice(0, 7);

        return matchesCustomer && matchesStatus && matchesMonth;
      });
    },
  },
  watch: {
    filteredInvoices: {
      handler() {
        this.calculateAmounts();
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchInvoices();
  },
  methods: {
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case 'open':
          return 'orange';
        case 'paid':
          return 'green';
        default:
          return 'gray';
      }
    },
    async fetchInvoices() {
      this.loading = true; // Start loading
      try {
        const customerId = 'cus_RV4pIqaO1mFH2j';
        const url = `https://robopower.xyz/v2/stripepayments/getcustomerinvoices?cust_id=${customerId}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        let invoicesArray = Array.isArray(data) ? data[0]?.data || data : data.data || [];

        this.invoices = invoicesArray.map((invoice, index) => ({
          id: index + 1,
          client: invoice.customer_name || "Unknown",
          email: invoice.customer_email || "Unknown",
          amount: invoice.amount_due ? invoice.amount_due / 100 : 0,
          status: invoice.status ? invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1) : "Unknown",
          date: invoice.date || new Date().toISOString(),
          hosted_invoice_url: invoice.hosted_invoice_url || "",
        }));

        this.customerOptions = [...new Set(this.invoices.map((invoice) => invoice.client))];
        this.selectedStatus = "All";
      } catch (error) {
        console.error("Error fetching invoices:", error.message);
      }finally {
        this.loading = false; // Stop loading
      }
    },
    calculateAmounts() {
      let total = 0;
      let open = 0;
      let paid = 0;
      this.filteredInvoices.forEach((invoice) => {
        total += invoice.amount;
        if (invoice.status.toLowerCase() === 'open') {
          open += invoice.amount;
        } else if (invoice.status.toLowerCase() === 'paid') {
          paid += invoice.amount;
        }
      });

      this.totalAmount = total;
      this.openAmount = open;
      this.paidAmount = paid;
    },
    applyFilters() {
      console.log('Filters applied:', {
        customer: this.selectedCustomer,
        status: this.selectedStatus,
        month: this.selectedMonth,
      });
      this.calculateAmounts();
    },
    clearMonthFilter() {
      this.selectedMonth = null;
      this.applyFilters();
    },
    downloadInvoice(invoice) {
      if (!invoice.hosted_invoice_url) {
        console.error("No invoice URL provided");
        alert("Invoice download link is missing.");
        return;
      }

      const link = document.createElement("a");
      link.href = invoice.hosted_invoice_url;
      link.download = `Invoice-${invoice.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openPaymentLink(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        console.error("No payment URL provided");
      }
    },
  },
};
</script>

<style scoped>
.summary-card {
  padding: 20px;
  text-align: center;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.total-card {
  background: linear-gradient(135deg, #1976d2, #64b5f6);
}

.pending-card {
  background: linear-gradient(135deg, #ff9800, #ffb74d);
}

.paid-card {
  background: linear-gradient(135deg, #4caf50, #81c784);
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #1976d2;
}

.table-header th {
  color: white; /* Ensure header text is white */
  font-weight: bold; /* Make header text bold */
  padding: 12px 16px;
  text-align: center;
  vertical-align: middle;
}

.invoice-row {
  background-color: #ffffff;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.invoice-row:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.pay-btn, .download-btn {
  font-weight: bold;
  border-radius: 6px;
  padding: 8px 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.pay-btn {
  background-color: #1976d2;
  color: white;
}

.download-btn {
  background-color: #4caf50;
  color: white;
}

.v-chip {
  font-weight: 500;
  font-size: 0.875rem;
}
</style>